/**
 * Created by geroe on 19.04.2016.
 */
// import { mixin, TLoggable } from 'fifa-javascript-utilities/GeneralUtils';
/* tslint:disable:no-any */
import { FilterDefinition } from './FilterDefinition';
import { SimpleFilter } from './SimpleFilter';
import { ValidationMessage } from './ValidationMessage';
import { assert, isArrayOf } from './utils';
import { FilterAggregator } from './FilterAggregator';
import { FilterOperator } from './FilterOperator';
import { FilterDataType, FilterType } from './FilterType';
import { chain, isString, uniq } from 'lodash';

export {
    FilterDefinition,
    SimpleFilter,
    ValidationMessage,
    FilterOperator,
    FilterDataType,
    FilterType
};

/*global Promise*/

/**
 * Main class that contains all information necessary for a view regarding
 * available filters. Methods to create a schema from various formats.
 */
export class FilterSchema {
    _definitions: any;

    constructor({ definitions }: any) {
        assert(
            isArrayOf(definitions, FilterDefinition),
            'Definitions must be Array of FilterDefinition'
        );
        this._definitions = definitions;
    }

    get definitions() {
        return this._definitions;
    }

    getDefaultFilter() {
        return this._definitions[0].getDefaultFilter();
    }

    getDefinition(path: any) {
        assert(isString(path), 'definition name must be a string');
        const fields = path.split('.');
        const name = fields.shift();
        const result = this._definitions.find((def: any) => def.name === name);

        assert(result, `can't find field ${name}`);

        return result.getSubField(fields);
    }

    serialize() {
        return {
            definitions: this._definitions.map((d: any) => d.serialize())
        };
    }

    /**
     * Validate a filter instance against the schema.
     * @param {SimpleFilter} filter
     */
    validateFilter(filter: any) {
        assert(
            filter && filter instanceof SimpleFilter,
            'filter must be SimpleFilter'
        );
        const field = this.getDefinition(filter.field);

        if (!field) {
            return new ValidationMessage({
                isValid: false,
                errorMessage: `unknown filter field "${filter.field}"`,
                value: filter
            });
        }

        return field.validateFilter(filter);
    }

    _uniqOperatorsOnly(array: any, fieldName: any) {
        assert(array && array instanceof Array, 'need an array');
        if (array.length !== uniq(array).length) {
            return new ValidationMessage({
                isValid: false,
                errorMessage: `Field "${fieldName}" contains not only unique operators!`
            });
        }

        return new ValidationMessage({
            isValid: true,
            errorMessage: ''
        });
    }

    validateFilterAggregate(aggregate: any) {
        assert(
            aggregate && aggregate instanceof FilterAggregator,
            'Aggregate must be FilterAggregator'
        );
        const uniqOnly = chain(aggregate.filters)
            .groupBy(filter => filter.field)
            .mapValues((filters, fieldName) =>
                this._uniqOperatorsOnly(
                    filters.map((filter: any) => filter.operator),
                    fieldName
                )
            )
            .values()
            .value();
        const validation = ValidationMessage.reduceMessages(
            uniqOnly,
            aggregate
        );

        if (!validation.isValid) {
            return validation;
        }

        return ValidationMessage.reduceMessages(
            aggregate.filters.map(this.validateFilter.bind(this)),
            aggregate
        );
    }
}
