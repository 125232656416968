import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
    MutationFunc,
    compose,
    graphql,
    gql,
    ApolloError,
    withApollo,
    ApolloClient
} from 'react-apollo';
import {message, notification} from 'antd';
import {State} from '../../store';
import {setAddContactModalVisibility} from './AddContactReducer';
import {
    AddContactModal,
    AddContactModalActionProps,
    AddContactModalDataProps
} from './AddContactModal';
import React from 'react';

export interface AddContactResult {
    id: string;
    name: string;
    username: string;
    email: string;
    phoneNumber: string;
}

export interface AddContactVariables {
    id?: string;
    name: string;
    username: string;
    email?: string;
    phoneNumber?: string;
}

export interface RemoveContactResult {
    id: string;
}

export interface RemoveContactVariables {
    id?: string;
}

export interface AddContactModalContainerProps {
    reportName: string;
}

export const mapStateToProps = (
    state: State,
    {reportName}: AddContactModalContainerProps
): AddContactModalDataProps => {
    const propsToSend = {
        visible:
            state.cia.addContactInteraction.visibleOnReport.get(reportName) ||
            false,
        data: state.cia.addContactInteraction.activeRowPerReport,
        functionality: state.cia.addContactInteraction.functionalityModal
    };
    return {
        ...propsToSend
    };
};

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    {
        reportName,
        addContact,
        editContact,
        removeContact,
        client
    }: AddContactModalContainerProps & {
        addContact: MutationFunc<AddContactResult, AddContactVariables>;
        editContact: MutationFunc<AddContactResult, AddContactVariables>;
        removeContact: MutationFunc<
            RemoveContactResult,
            RemoveContactVariables
        >;
        client: ApolloClient;
    }
): AddContactModalActionProps => ({
    onCancel: () =>
        dispatch(setAddContactModalVisibility(reportName, {}, '', false)),
    onAddSubmit: async values => {
        try {
            await addContact({
                variables: {
                    ...values
                }
            });
            dispatch(setAddContactModalVisibility(reportName, {}, '', false));
            client.resetStore();
            message.success('Successfully added your contact!');
            return true;
        } catch (error) {
            if (error.graphQLErrors) {
                const apolloError = error as ApolloError;
                for (const err of apolloError.graphQLErrors) {
                    message.error(err.message);
                }
            }
            throw error;
        }
    },
    onEditSubmit: async values => {
        try {
            await editContact({
                variables: {
                    ...values
                }
            });
            dispatch(setAddContactModalVisibility(reportName, {}, '', false));
            client.resetStore();
            message.success('Successfully edited your contact!');
            return true;
        } catch (error) {
            if (error.graphQLErrors) {
                const apolloError = error as ApolloError;
                for (const err of apolloError.graphQLErrors) {
                    message.error(err.message);
                }
            }
            throw error;
        }
    },
    onRemoveSubmit: async values => {
        try {
            await removeContact({
                variables: {
                    ...values
                }
            });
            dispatch(setAddContactModalVisibility(reportName, {}, '', false));
            client.resetStore();
            message.success('Successfully removed your contact!');
            return true;
        } catch (error) {
            if (error.graphQLErrors) {
                const apolloError = error as ApolloError;
                apolloError.graphQLErrors.forEach(err => {
                    if (
                        err.message.indexOf(
                            'Contact cannot be removed when assigned to certificates'
                        ) >= 0
                    ) {
                        err.message = err.message.split(
                            'Contact cannot be removed when assigned to certificates: \n'
                        )[1];

                        let assignedCertificates: React.ReactNode[] = err.message
                            .split('\n')
                            .map(el => {
                                return <li key={el}>{el}</li>;
                            });
                        assignedCertificates.pop();

                        notification.error({
                            duration: 0,
                            message:
                                'Contact is still assigned to certificates:',
                            description: <ul>{assignedCertificates}</ul>
                        });
                    } else {
                        message.error(err.message);
                    }
                });
            }
            return false;
        }
    }
});

const addContactMutation = gql`
    mutation(
        $name: SaveStringType!
        $username: SaveStringType!
        $email: Email!
        $phoneNumber: SaveStringType
    ) {
        contact {
            add(
                name: $name
                username: $username
                email: $email
                phoneNumber: $phoneNumber
            ) {
                id
                name
                username
                email
                phoneNumber
            }
        }
    }
`;

const editContactMutation = gql`
    mutation(
        $id: ID!
        $name: SaveStringType
        $username: SaveStringType
        $email: Email
        $phoneNumber: SaveStringType
    ) {
        contact {
            edit(
                id: $id
                name: $name
                username: $username
                email: $email
                phoneNumber: $phoneNumber
            ) {
                id
                name
                username
                email
                phoneNumber
            }
        }
    }
`;

const removeContactMutation = gql`
    mutation($id: ID!) {
        contact {
            remove(id: $id) {
                id
            }
        }
    }
`;

export const AddContactModalContainer: React.ComponentClass<
    AddContactModalContainerProps
> = compose(
    withApollo,
    graphql(addContactMutation, {name: 'addContact'}),
    graphql(editContactMutation, {name: 'editContact'}),
    graphql(removeContactMutation, {name: 'removeContact'}),
    connect(mapStateToProps, mapDispatchToProps)
)(AddContactModal);
