import * as React from 'react';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import notification from 'antd/lib/notification';
import Spin from 'antd/lib/spin';
import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';

const { Content } = Layout;

interface MakeDecisionProps {
    error: ApolloError;
    domainName: string;
    type: string;
    expirationDate: string;
    salesLines: string[];
    onRenew: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
    onExpire:
    | ((event: React.MouseEvent<HTMLButtonElement>) => void)
    | undefined;
}

export class MakeDecisionView extends React.Component<
    MakeDecisionProps,
    {
        hasBeenClicked: boolean;
    }
> {
    onRenewHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;

    constructor(props: MakeDecisionProps) {
        super(props);

        this.state = { hasBeenClicked: false };

        this.onRenewHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
            this.setState({ hasBeenClicked: true });
            if (this.props.onRenew) {
                this.props.onRenew(event);
            }
        };
    }

    render() {
        if (this.props.error) {
            let e: GraphQLError;
            for (e of this.props.error.graphQLErrors) {
                notification.error({
                    message: 'An Error ocurred',
                    description: e.message
                });
                return (
                    <Content>
                        <div>
                            <h2>An Error occured:</h2>
                            <p>
                                Error message: {e.message}
                                <br />
                                <br />
                                {e.message.indexOf(
                                    'No certificate found for token'
                                ) >= 0
                                    ? 'It seems like there is no decision pending for this token.'
                                    : ''}
                            </p>
                        </div>
                    </Content>
                );
            }
        }

        return (
            <Content>
                <h2>Certificate is expiring!</h2>
                <p>
                    On this site, you can make the decision of either renewing
                    the certificate by creating a ticket or to let the
                    certificate expire.
                </p>
                <span>domainName: {this.props.domainName}</span>
                <br />
                <span>type: {this.props.type}</span>
                <br />
                <span>expirationDate: {this.props.expirationDate}</span>
                <br />
                <span>salesLines: {this.props.salesLines}</span>
                <br />
                <br />
                <Row>
                    <Col xs={{ span: 12 }} md={{ span: 6 }}>
                        <Button
                            className="onRenew"
                            type="primary"
                            onClick={this.onRenewHandler}
                            disabled={this.state.hasBeenClicked}
                        >
                            renew
                            <Spin
                                style={{
                                    display: this.state.hasBeenClicked
                                        ? 'inline-block'
                                        : 'none',
                                    marginTop: 5,
                                    marginLeft: 11
                                }}
                            />
                        </Button>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 6 }}>
                        <Button
                            className="onExpire"
                            type="default" danger
                            onClick={this.props.onExpire}
                            disabled={this.state.hasBeenClicked}
                        >
                            expire
                        </Button>
                    </Col>
                </Row>
            </Content>
        );
    }
}
