/**
 * Created by geroe on 19.04.2016.
 */
/* tslint:disable:no-any */
import { FilterType } from './FilterType';
import { assert } from './utils';

export { FilterType };

const DisplayNames = {
    in: 'is one of',
    nin: 'is not one of',
    gt: 'is greater than',
    lt: 'is less than',
    gte: 'is greater than or equals',
    lte: 'is less than or equals',
    eq: 'equals',
    ne: 'does not equal',
    regex: 'contains'
};

export class FilterOperator {
    _name: any;
    _displayName: any;
    _type: any;

    constructor({ name, type, displayName }: any) {
        assert(name, 'Operator must be named');
        assert(
            type && type instanceof FilterType,
            `invalid filter type '${type}'`
        );
        this._name = name;
        this._displayName = displayName;
        this._type = type;
    }

    get name() {
        return this._name;
    }

    get displayName() {
        return this._displayName || DisplayNames[this._name] || this._name;
    }

    get type() {
        return this._type;
    }

    serialize() {
        return {
            name: this._name,
            displayName: this._displayName,
            type: this._type.serialize()
        };
    }

    validateValue(value: any) {
        return this._type
            .validateValue(value)
            .addMessagePrefix(`[operator "${this.displayName}"] `);
    }
}
