import { Reducer, Action } from 'redux';
import { ActionNames } from './GraphQLMenuActionNames';

export interface GraphQLMenuState {
    isCollapsed: boolean;
}

const defaultState: GraphQLMenuState = {
    isCollapsed: true
};

export const GraphQLMenuReducer: Reducer<GraphQLMenuState | undefined> = (
    state = defaultState,
    action: Action
) => {
    switch (action.type) {
        case ActionNames.toggleColapsed:
            return { isCollapsed: !state.isCollapsed };
        default:
            return state;
    }
};
