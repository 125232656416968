/**
 * Created by geroe on 12.08.2016.
 */
/* tslint:disable:no-any */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    convertBase64ToObjectWithFallback,
    createPushAction
} from '../../util/UrlUtil';
import { withApollo } from 'react-apollo';
import { defaultPaginatonOptions } from '../../util/UrlUtil';
import { PresentationalFilterContainer } from './FilterPresentational';

const mapStateToProps = (state: any, ownProps: any) => ({
    type: ownProps.type,
    endpoint: ownProps.endpoint,
    currentFilters: convertBase64ToObjectWithFallback(
        ownProps.match.params.filter,
        defaultPaginatonOptions
    )
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
    return {
        push: (filters: any) => {
            // to reset pagination after filtering
            // to avoid landing on e.g. page 3 when only one result was found
            filters.paginationOptions.activePage = 1;

            return dispatch(
                createPushAction({
                    path: ownProps.match.path,
                    pathType: 'absolute',
                    filters: filters
                })
            );
        }
    };
};

export const FilterContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withApollo(PresentationalFilterContainer)
    )
);
