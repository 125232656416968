import * as React from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
    AddCertificateInteractionAction,
    ShowAddCertificateModalPayload
} from './AddCertificateReducer';
import { CertificateClaims } from '../claims/ClaimsReducer';

export interface AddCertificateStateProps {
    certificateClaims: CertificateClaims;
}

export interface CertificateDisplayProps {
    onShowDetails: () => AddCertificateInteractionAction<
        ShowAddCertificateModalPayload
    >;
}

export interface AddCertificateProps
    extends CertificateDisplayProps,
        AddCertificateStateProps {}

export const AddCertificateDisplay: React.SFC<AddCertificateProps> = ({
    onShowDetails,
    certificateClaims
}) => {
    if (
        certificateClaims.add.length > 0 &&
        certificateClaims.add[0].length > 0
    ) {
        return (
            <Button onClick={onShowDetails} icon={<DoubleRightOutlined />}>
                New Certificate
            </Button>
        );
    } else {
        return <div />;
    }
};
