import { SubTranslationObject } from 'react-redux-i18n';

export const en: SubTranslationObject = {
    WelcomeView: {
        title: 'Welcome to CIA - Certificate Information Administration'
    },
    FileNotFoundView: {
        text: 'Sorry, the requested View does not exist'
    }
};
