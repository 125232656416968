interface AfterwareResponse {
    response: Response;
    options: RequestInit;
}

interface NotAuthorizedResponse {
    bouncerUrl: string;
}

export const HttpStatusCodeHandler = {
    applyAfterware(res: AfterwareResponse, next: Function) {
        const { response } = res;
        switch (response.status) {
            case 401:
                response
                    .clone()
                    .json()
                    .then((data: NotAuthorizedResponse) => {
                        window.localStorage.setItem(
                            'loginRedirect',
                            location.hash.replace('#', '')
                        );
                        window.location.replace(data.bouncerUrl);
                    });
                break;
            default:
        }
        next();
    }
};
