import * as React from 'react';
import { TargetType } from './Filter';
import {
    FilterMapper,
    ComponentMapping,
    FilterInputComponentProps
} from './FilterMapper';
import {
    MultiStringInput,
    SingleStringInput,
    MultiNumberInput,
    SingleNumberInput,
    SingleDateInput,
    SingleBoolInput,
    EnumInput
} from './DefaultInputComponent';
import { Input } from 'antd';

export interface TargetTypeComponentProps {
    targetType: TargetType;
    multible: boolean;
    filterMapper?: FilterMapper;
    onSelectChange: (newValue: string) => void;
    onDeselectChange: (removeValue: string) => void;
    defaultTargets: Array<string>;
}

const defaultFilterMap: ComponentMapping = new Map();
defaultFilterMap.set('String', {
    multiSelectComponent: MultiStringInput,
    singleSelectComponent: SingleStringInput
});

defaultFilterMap.set('Number', {
    singleSelectComponent: SingleNumberInput,
    multiSelectComponent: MultiNumberInput
});

defaultFilterMap.set('DateTime', {
    singleSelectComponent: SingleDateInput
});

defaultFilterMap.set('Boolean', {
    singleSelectComponent: SingleBoolInput
});

defaultFilterMap.set('Enum', {
    singleSelectComponent: EnumInput(false),
    multiSelectComponent: EnumInput(true)
});

const defaultFilterMapper = new FilterMapper(defaultFilterMap);

const targetTypeNameMapper = (value: Array<string>, multible: boolean) => {
    if (multible) {
        return value;
    } else {
        return value[0];
    }
};

export const TargetTypeComponent: React.SFC<TargetTypeComponentProps> = ({
    targetType,
    multible,
    filterMapper = defaultFilterMapper,
    onSelectChange,
    onDeselectChange,
    defaultTargets
}) => {
    try {
        if (multible) {
            const Component: React.SFC<
                FilterInputComponentProps
            > = filterMapper.getMultiComponentByTargetType(targetType);
            return (
                <Component
                    targetType={targetType}
                    onValueChange={onSelectChange}
                    onDeselectChange={onDeselectChange}
                    defaultTargetTypeValue={targetTypeNameMapper(
                        defaultTargets,
                        true
                    )}
                />
            );
        } else {
            const Component: React.SFC<
                FilterInputComponentProps
            > = filterMapper.getSimpleComponentByTargetType(targetType);
            return (
                <Component
                    onValueChange={onSelectChange}
                    targetType={targetType}
                    onDeselectChange={onDeselectChange}
                    defaultTargetTypeValue={targetTypeNameMapper(
                        defaultTargets,
                        false
                    )}
                />
            );
        }
    } catch (ex) {
        return <Input disabled={true} size="small" />;
    }
};
