import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
    Store
} from 'redux';
import {
    ApolloClient,
    createNetworkInterface,
    NetworkInterface
} from 'react-apollo';
import { mockNetworkInterface, MockedResponse } from 'react-apollo/test-utils';
import thunk from 'redux-thunk';
import {
    loadTranslations,
    setLocale,
    syncTranslationWithStore,
    i18nReducer
} from 'react-redux-i18n';
import { History } from 'history';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { HttpStatusCodeHandler } from './NetworkInterfaceAfterware';
import { CiaReducer } from './Reducer';
import { locales } from '../locale';

export let reduxStore: Store<{}>;
export let apolloClient: ApolloClient;

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__: Function;
    }
}

interface StoreInitialization {
    networkInterface?: NetworkInterface;
    useReduxDevtoolsExtension: boolean;
    history: History;
}

interface ApolloStoreEnvelope {
    client: ApolloClient;
    store: Store<{}>;
}

function createCombinedStore({
    networkInterface,
    useReduxDevtoolsExtension,
    history
}: StoreInitialization): ApolloStoreEnvelope {
    const apolloConfig = { networkInterface };
    const client = new ApolloClient(apolloConfig);

    const middleware = [client.middleware(), routerMiddleware(history), thunk];

    const store: any = createStore(
        combineReducers({
            cia: CiaReducer,
            apollo: client.reducer(),
            router: routerReducer,
            i18n: i18nReducer
        }),
        useReduxDevtoolsExtension
            ? compose(
                applyMiddleware(...middleware),
                window.__REDUX_DEVTOOLS_EXTENSION__()
            )
            : compose(applyMiddleware(...middleware))
    );

    syncTranslationWithStore(store);
    loadTranslations(locales)(store.dispatch);
    setLocale('en')(store.dispatch);

    return {
        client,
        store
    };
}

/**
 * creates the redux store and the apollo client
 * @param uri url to backend
 * @param useReduxDevtoolsExtension
 */
export function createStoreWithBackend({
    useReduxDevtoolsExtension = true,
    history
}: StoreInitialization): ApolloStoreEnvelope {
    const networkInterface = createNetworkInterface({
        // uri: `http://localhost:8080/api/graphql`,
        // uri: `http://cia-test.media-saturn.com/api/graphql`,
        uri: `${location.hostname.indexOf('fifa') !== -1 ? '/cia' : ''
            }/api/graphql`,
        opts: {
            credentials: 'same-origin'
        }
    });

    networkInterface.useAfter([HttpStatusCodeHandler]);

    return createCombinedStore({
        networkInterface,
        useReduxDevtoolsExtension,
        history
    });
}

/**
 * creates the redux store and the apollo client
 * mocks the network interface, you need to provide mocks
 * @param mocks ( array of {reques, response} )
 * @param history
 */
export const createTestStore = (
    mocks: Array<MockedResponse>,
    history: History
) =>
    createCombinedStore({
        networkInterface: mockNetworkInterface.apply(null, mocks),
        useReduxDevtoolsExtension: false,
        history
    });

export function initialize(
    storeInit: StoreInitialization
): ApolloStoreEnvelope {
    const envelope = createStoreWithBackend(storeInit);
    apolloClient = envelope.client;
    reduxStore = envelope.store;

    return envelope;
}
