import * as React from 'react';
import { Table, Layout } from 'antd';
import { URLPagination } from '../../../component/pagination';
import { Columns } from './CertificateTableViewColumns';
import { DetailsModalContainer } from '../../../component/certificateInteraction/DetailsModalContainer';
var moment = require('moment');
import './CertificateTableView.css';
import { AddCertificateDisplayContainer } from '../../../component/addCertificateInteraction/AddCertificateDisplayContainer';
import { AddCertificateModalContainer } from '../../../component/addCertificateInteraction/AddCertificateModalContainer';
import { FilterBox } from '../../../component/filterBox/FilterBox';
import { ColorLegend } from './ColorLegend';
import { CertificateRunContainer } from '../../../component/certificateRun/CertificateRunContainer';
const { Content } = Layout;

export interface CertificateTableDetails {
    certificateDetails: {};
    contactData: [{}];
    header: {
        id: string;
        domainName: string;
        type: string;
        cherwellTicket: number;
        status: string;
        allowedToExpire: string;
        expirationDate: string;
        certPort: number;
    };
    issuerDetails: {};
    ownerDetails: {};
}

export interface CertificateTableProps {
    rowData: Array<CertificateTableDetails>;
    numberOfRows: number;
    loading: boolean;
    reportName: string;
    visibleColumns: string[];
    error?: {
        message?: string;
    };
}

export interface ExpirationDateColoring {
    red: number;
    orange: number;
    yellow: number;
}

export const expirationDateColoring: ExpirationDateColoring = {
    red: 0,
    orange: 5,
    yellow: 30
};

const LegendItemArray = [
    {
        cssClass: 'RowAllowedToExpire',
        text: 'Certificate is allowed to expire'
    },
    {
        cssClass: 'RowExpirationNull',
        text: 'Certificate has no expirationDate'
    },
    {
        cssClass: 'RowExpirationRed',
        text: `Certificate expirationDate <= ${expirationDateColoring.red} days`
    },
    {
        cssClass: 'RowExpirationOrange',
        text: `Certificate expirationDate <= ${expirationDateColoring.orange
            } days`
    },
    {
        cssClass: 'RowExpirationYellow',
        text: `Certificate expirationDate <= ${expirationDateColoring.yellow
            } days`
    }
];

export const getRowClass = (record: CertificateTableDetails) => {
    const allowedToExpireRow = record.header.allowedToExpire;
    const difference = moment().diff(
        moment(new Date(record.header.expirationDate)),
        'days'
    );

    if (allowedToExpireRow) {
        return 'RowAllowedToExpire';
    } else if (record.header.expirationDate === '1900-01-01T00:00:00.000Z') {
        return 'RowExpirationNull';
    } else if (difference >= expirationDateColoring.red) {
        return 'RowExpirationRed';
    } else if (difference >= -expirationDateColoring.orange) {
        return 'RowExpirationOrange';
    } else if (difference >= -expirationDateColoring.yellow) {
        return 'RowExpirationYellow';
    } else {
        return '';
    }
};

export const mapTableData = (data: Array<CertificateTableDetails>) => {
    return data.map(certificate => ({
        domainName: certificate.header.domainName,
        type: certificate.header.type,
        status: certificate.header.status,
        cherwellTicket: certificate.header.cherwellTicket,
        allowedToExpire: certificate.header.allowedToExpire,
        expirationDate: certificate.header.expirationDate,
        port: certificate.header.certPort,
        certificateDetails: certificate.certificateDetails,
        contactData: certificate.contactData,
        header: certificate.header
    }));
};

export const CertificateTableView: React.SFC<CertificateTableProps> = ({
    loading,
    rowData,
    numberOfRows,
    reportName,
    visibleColumns,
    error
}) => {
    const propsToSend = {
        numberOfItems: numberOfRows,
        rowsPerPageOptions: ['10', '25', '50', '100']
    };
    const propsForFilter = {
        type: 'CertificateViewModelType',
        reportName: 'CERTIFICATE',
        columnsAvailable: columnsAvailable
    };
    if (error) {
        return (
            <Content>
                <div>
                    <h3>An Error occured during your Request</h3>
                    <p>
                        {error.message &&
                            error.message.indexOf(
                                'GraphQL error: Cannot view cert'
                            ) >= 0
                            ? 'It seems like you do not have the rights to access this view'
                            : ''}
                    </p>
                </div>
            </Content>
        );
    }
    return (
        <Content>
            <CertificateRunContainer />
            <h1 style={{ textAlign: 'center' }}>Certificates</h1>
            <FilterBox {...propsForFilter} />
            <ColorLegend legendItems={LegendItemArray} />
            <AddCertificateDisplayContainer reportName={'ADDCERTIFICATE'} />
            <div style={{ marginBottom: '5px' }}>
                <URLPagination {...propsToSend} />
            </div>
            <Table
                loading={loading}
                rowKey={(record: { header: { id: string } }) =>
                    record.header.id
                }
                pagination={false}
                columns={Columns('CERTIFICATE', visibleColumns)}
                dataSource={mapTableData(rowData)}
                bordered={false}
                size="small"
                rowClassName={getRowClass}
            />
            <URLPagination {...propsToSend} />
            <ColorLegend legendItems={LegendItemArray} />
            <AddCertificateDisplayContainer reportName={'ADDCERTIFICATE'} />
            <DetailsModalContainer reportName={'CERTIFICATE'} />
            <AddCertificateModalContainer reportName={'ADDCERTIFICATE'} />
        </Content>
    );
};

const columnsAvailable = [
    // {key: 'domainName', name:'Domain Name'},
    { key: 'type', name: 'Type' },
    { key: 'status', name: 'Current Status' },
    { key: 'cherwellTicket', name: 'Cherwell' },
    { key: 'allowedToExpire', name: 'Allowed to Expire' },
    { key: 'expirationDate', name: 'Expiration Date' },
    { key: 'daysUntilExpiration', name: 'Days Until Expiration' },
    { key: 'port', name: 'Port' }
];
