/**
 * Created by geroe on 19.04.2016.
 */
// import { mixin, TLoggable } from 'fifa-javascript-utilities/GeneralUtils';
/* tslint:disable:no-any */
import { assert, isArrayOf } from './utils';
// import { FilterType } from './FilterType';
import { FilterOperator } from './FilterOperator';
import { ValidationMessage } from './ValidationMessage';
import { SimpleFilter } from './SimpleFilter';
import { isArray, isString } from 'lodash';

export class FilterDefinition {
    _name: any;
    _displayName: any;
    _operators: any;
    _subFields: any;

    constructor(params: any) {
        this._validateConstructor(params);
        this._init(params);
    }

    _validateConstructor({ name, displayName, operators, subFields }: any) {
        assert(name && isString(name), 'Name must be string');
        assert(
            !displayName || isString(displayName),
            'displayName must be string'
        );
        const opsValid = isArrayOf(operators, FilterOperator);
        const sfValid = isArrayOf(subFields, FilterDefinition);

        assert(
            operators ? !subFields : subFields,
            'either operators or subFields must be defined'
        );
        assert(
            opsValid ? !sfValid : sfValid,
            'either operators or subFields must be valid'
        );
    }

    _init({ name, displayName, operators, subFields }: any) {
        this._name = name;
        this._displayName = displayName;
        this._operators = operators;
        this._subFields = subFields;
    }

    get name() {
        return this._name;
    }

    get displayName() {
        return this._displayName || this._name;
    }

    get operators() {
        return this._operators;
    }

    get subFields() {
        return this._subFields;
    }

    getOperator(name: any) {
        return this._operators.find((operator: any) => operator.name === name);
    }

    _getFirstSimple(path: any = []) {
        if (!this.isComplex()) {
            return {
                path: path.concat(this.name).join('.'),
                definition: this
            };
        }

        return this._subFields[0]._getFirstSimple(path.concat(this.name));
    }

    getDefaultFilter() {
        const result = this._getFirstSimple();

        return new SimpleFilter({
            field: result.path,
            operator: result.definition.operators[0].name,
            value: result.definition.operators[0].type.defaultValue()
        });
    }

    serialize() {
        return {
            name: this._name,
            displayName: this._displayName,
            operators:
                this._operators &&
                this._operators.map((o: any) => o.serialize()),
            subFields:
                this._subFields &&
                this._subFields.map((f: any) => f.serialize())
        };
    }

    getSubField(pathArray: any) {
        assert(isArray(pathArray), 'path must be array');
        if (pathArray.length === 0) {
            return this;
        }
        assert(this.isComplex(), 'this is not a complex field');
        const name = pathArray.shift();
        const field = this._subFields.find(
            (afield: any) => afield.name === name
        );

        return field.getSubField(pathArray);
    }

    isComplex() {
        return !!this._subFields;
    }

    _validateField(field: any) {
        const result = {
            isValid: field === this._name,
            errorMessage: ''
        };

        if (!result.isValid) {
            result.errorMessage = 'wrong field name';
        }

        return new ValidationMessage(result);
    }

    _validateOperator(operator: any, value: any) {
        const op = this._operators.find((aop: any) => aop.name === operator);

        if (!op) {
            return new ValidationMessage({
                isValid: false,
                errorMessage: 'unknown operator',
                value: operator
            });
        }
        return op.validateValue(value);
    }

    /**
     * Check if the filter complies with the FilterDefinition
     * @param {SimpleFilter} filter
     * @returns {ValidationMessage}
     */
    validateFilter(filter: any) {
        assert(
            filter && filter instanceof SimpleFilter,
            'filter must be SimpleFilter'
        );
        const opValid = this._validateOperator(filter.operator, filter.value);

        return ValidationMessage.reduceMessages(
            [/*fieldValid, */ opValid],
            filter
        ).addMessagePrefix(`[field "${filter.field}"] `);
    }
}
