import * as React from 'react';
import { AddContactForm } from './AddContactForm';
import {
    AddContactVariables,
    RemoveContactVariables
} from './AddContactModalContainer';
import { ErrorProps } from '../addCertificateInteraction/AddCertificateModal';

export interface AddContactModalDataProps {
    visible: boolean;
    data: {
        id: string;
    };
    functionality: string;
}

export interface AddContactModalActionProps {
    onCancel: () => void;
    onAddSubmit: (values: AddContactVariables) => Promise<boolean>;
    onEditSubmit: (values: AddContactVariables) => Promise<boolean>;
    onRemoveSubmit: (values: RemoveContactVariables) => Promise<boolean>;
}

export interface AddContactModalProps
    extends AddContactModalDataProps,
        AddContactModalActionProps {}

export interface AddContactModalState {
    // tslint:disable-next-line
    formRef: any;
}

interface ContactFormErrorProps {
    name: [ErrorProps];
    username: [ErrorProps];
}

export class AddContactModal extends React.Component<
    AddContactModalProps,
    AddContactModalState
> {
    constructor(props: AddContactModalProps) {
        super(props);
        this.state = { formRef: null };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = () => {
        const form = this.state.formRef.props.form;
        form.validateFields(
            async (err: ContactFormErrorProps, values: AddContactVariables) => {
                if (err) {
                    return;
                }
                if (this.props.functionality === 'EDIT') {
                    await this.props.onEditSubmit({
                        ...values,
                        id: this.props.data.id
                    });
                } else if (this.props.functionality === 'ADD') {
                    await this.props.onAddSubmit(values);
                } else if (this.props.functionality === 'REMOVE') {
                    await this.props.onRemoveSubmit({
                        id: this.props.data.id
                    });
                }
                form.resetFields();
            }
        );
    };

    handleCancel = () => {
        const form = this.state.formRef.props.form;
        form.resetFields();
        this.props.onCancel();
    };

    saveFormRef = (formRef: Symbol) => {
        this.setState({ formRef: formRef });
    };

    render() {
        const { visible, data, functionality } = this.props;
        return (
            <AddContactForm
                wrappedComponentRef={this.saveFormRef}
                visible={visible}
                data={data}
                functionality={functionality}
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
            />
        );
    }
}
