import * as React from 'react';
import { CheckOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse, Button } from 'antd';
import { FilterRowRender, TreeData } from './Filter';
import { FilterRow } from './FilterRow';
import { FilterMapper } from './FilterMapper';

export interface FilterPanelProps {
    filters: Array<FilterRowRender>;
    filterTree: Array<TreeData>;
    onClearFilters: () => void;
    onAddFilter: () => void;
    onRemoveFilter: (filter: FilterRowRender) => void;
    filterMapper?: FilterMapper;
    onApplyFilter: () => void;
    onFilterChange: (filter: FilterRowRender, filterId: string) => void;
    onOperatorChange: (filter: FilterRowRender, operatorId: string) => void;
    onSelectChange: (filter: FilterRowRender, newValue: string) => void;
    onDeselectChange: (filter: FilterRowRender, removeValue: string) => void;
}

const { Panel } = Collapse;
const { Group } = Button;

export const FilterPanel: React.SFC<FilterPanelProps> = ({
    filters,
    filterTree,
    onAddFilter,
    onClearFilters,
    onRemoveFilter,
    filterMapper,
    onApplyFilter,
    onFilterChange,
    onOperatorChange,
    onSelectChange,
    onDeselectChange
}) => {
    return (
        <Collapse
            style={{
                marginBottom: '15px'
            }}
        >
            <Panel key="filters" header="Filters">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}
                >
                    {filters.map(filter => (
                        <FilterRow
                            key={filter.uuid}
                            filter={filter}
                            filterTree={filterTree}
                            onRemove={() => onRemoveFilter(filter)}
                            filterMapper={filterMapper}
                            onFilterChange={e => onFilterChange(filter, e)}
                            onOperatorChange={e => onOperatorChange(filter, e)}
                            onSelectChange={e => onSelectChange(filter, e)}
                            onDeselectChange={e => onDeselectChange(filter, e)}
                        />
                    ))}
                    <Group
                        style={{ alignSelf: 'flex-end', paddingTop: '15px' }}
                    >
                        <Button onClick={onAddFilter}>
                            <PlusOutlined /> Add
                        </Button>
                        <Button type="primary" danger onClick={onClearFilters}>
                            <DeleteOutlined /> Clear
                        </Button>
                        <Button
                            style={{ color: 'green' }}
                            onClick={onApplyFilter}
                        >
                            <CheckOutlined /> Apply
                        </Button>
                    </Group>
                </div>
            </Panel>
        </Collapse>
    );
};
