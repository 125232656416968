import React from 'react';
import { Collapse, Divider } from 'antd';
import { FilterContainer } from '../ciaFilter/FilterContainer';
import { ColumnToggleContainer } from '../columnToggle/ColumnToggleContainer';
const Panel = Collapse.Panel;

interface FilterBoxProps {
    type: string;
    reportName: string;
    columnsAvailable: Array<{ key: string; name: string }>;
}

export class FilterBox extends React.Component<FilterBoxProps, never> {
    constructor(props: FilterBoxProps) {
        super(props);
    }

    render() {
        const propsForFilter = {
            type: this.props.type
        };
        const propsForToggle = {
            reportName: this.props.reportName,
            columnsAvailable: this.props.columnsAvailable
        };
        return (
            <div style={{marginBottom: '5px'}}>
                <Collapse>
                    <Panel header="Filters" key="1">
                        <div>Rows:</div>
                        <FilterContainer {...propsForFilter} />
                        {this.props.reportName !== 'NONE' ? (
                            <div>
                                <Divider />
                                <div>Hide Columns:</div>
                                <ColumnToggleContainer {...propsForToggle} />
                            </div>
                        ) : (
                            undefined
                        )}
                    </Panel>
                </Collapse>
            </div>
        );
    }
}
