import * as React from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { FileNotFoundView } from './gui/component/default';
import { GraphQLMenuContainer } from './gui/component/graphQLMenu';
import './App.css';
import { ContactTableViewContainer } from './gui/view/contactTable/details/ContactTableViewContainer';
import { CertificateTableViewContainer } from './gui/view/certificateTable/details/CertificateTableViewContainer';
import { ClaimsContainer } from './gui/component/claims/ClaimsContainer';
import { MakeDecisionViewContainer } from './gui/view/makeDecision/MakeDecisionViewContainer';
import { DashboardViewContainer } from './gui/view/dashboard/DashboardContainer';
import { LocationRedirectContainer } from './gui/component/locationRedirect/LocationRedirectContainer';

const App = () => (
    <Layout className="rootLayout">
            <ClaimsContainer />
            <GraphQLMenuContainer />
            <Switch>
                <Route
                    path="/"
                    exact={true}
                    component={DashboardViewContainer}
                />
                <Route
                    path="/contact/:filter?"
                    component={ContactTableViewContainer}
                />
                <Route
                    path="/certificate/:filter?"
                    component={CertificateTableViewContainer}
                />
                <Route
                    path="/makeDecision/:token"
                    component={MakeDecisionViewContainer}
                />
                <Route path="/" component={FileNotFoundView} />
            </Switch>
            <LocationRedirectContainer />
    </Layout>
);

export default App;
