import * as React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';

export interface LegendItem {
    cssClass: string;
    text: string;
}

export interface LegendProps {
    legendItems: Array<LegendItem>;
}

const colorItems = (legendItems: LegendItem[]) => {
    return (legendItems.map((el: LegendItem) => {
        return (
            <div
                key={el.cssClass}
                className={el.cssClass}
                style={{
                    padding: 5,
                    width: '250px'
                }}
            >
                {el.text}
            </div>
        );
    }));
};

export const ColorLegend: React.SFC<LegendProps> = (props: LegendProps) => (
    <Popover content={colorItems(props.legendItems)}>
        <Button style={{marginRight: '5px'}}><InfoCircleOutlined />Color Legend</Button>
    </Popover>
);
