import * as React from 'react';
import { CalendarOutlined, PieChartOutlined } from '@ant-design/icons';
import { Layout, Card } from 'antd';
import { Translate } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
var moment = require('moment');

export interface DashboardProps extends RouteComponentProps<{}> {
    newestRunDate: string;
    oldestRunDate: string;
    certificateStatus: {}[];
    updateStatus: {}[];
}

const ColorArray = [
    { name: 'certificateNotNeeded', color: '#247BA0' },
    { name: 'certificateCouldNotBeRead', color: '#FE5F55' },
    { name: 'renewalInProgress', color: '#DABFFF' },
    { name: 'running', color: '#C7EFCF' },
    { name: 'manualUpdateRequired', color: '#F7D002' },
    { name: 'manuallyMaintained', color: '#4F518C' },
    { name: 'contactInformed', color: '#7FDEFF' },
    { name: 'successful', color: '#C7EFCF' },
    { name: 'failed', color: '#FE5F55' }
];

export const DashboardView: React.SFC<DashboardProps> = ({
    newestRunDate,
    oldestRunDate,
    certificateStatus,
    updateStatus
}) => {
    var newestRunDateV = newestRunDate;
    var oldestRunDateV = oldestRunDate;

    if (newestRunDate !== 'loading...' && newestRunDate !== '') {
        newestRunDateV = moment(newestRunDate).format(
            'DD[/]MM[/]YYYY, h:mm:ss a'
        );
    }
    if (oldestRunDate !== 'loading...' && oldestRunDate !== '') {
        oldestRunDateV = moment(oldestRunDate).format(
            'DD[/]MM[/]YYYY, h:mm:ss a'
        );
    }
    const runTitle = (
        <span>
            <CalendarOutlined style={{ fontSize: 20, color: '#08c' }} />
            Run Date:
        </span>
    );

    const statTitle = (
        <span>
            <PieChartOutlined style={{ fontSize: 20, color: '#08c' }} />
            Certificate Type and Update Stats:
        </span>
    );

    const RADIAN = Math.PI / 180;

    const CustomizedLabel = (props: any) => {
        const {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            fill,
            value,
            name
        } = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                fill={fill}
                textAnchor={x > cx ? 'start' : 'end'}
            >{`${name}: ${value}`}</text>
        );
    };
    const mapColor = (type: string) => {
        const color = ColorArray.find(piece => piece.name === type);
        return color ? color.color : 'black';
    };

    return (
        <Layout>
            <h1
                style={{
                    textAlign: 'center'
                }}
            >
                <b style={{ fontSize: '40px' }}>
                    <Translate value="WelcomeView.title" />
                </b>
            </h1>
            <div style={{ padding: '20px' }}>
                <Card title={runTitle} bordered={false} style={{ width: 300 }}>
                    <div>
                        Newest: <b>{newestRunDateV}</b>
                    </div>
                    <div>
                        Oldest: <b>{oldestRunDateV}</b>
                    </div>
                </Card>
            </div>
            <div style={{ padding: '20px' }}>
                <Card
                    title={statTitle}
                    bordered={false}
                    style={{ width: 1000 }}
                >
                    <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                            <Pie
                                key={'certificatePieChart'}
                                data={certificateStatus}
                                dataKey="count"
                                nameKey="type"
                                cx={'30%'}
                                innerRadius={20}
                                outerRadius={80}
                                label={<CustomizedLabel />}
                            >
                                {certificateStatus.map(
                                    (entry: { type: string }, index) => {
                                        return (
                                            <Cell
                                                key={'certChart' + index}
                                                fill={mapColor(entry.type)}
                                            />
                                        );
                                    }
                                )}
                            </Pie>
                            <Pie
                                key={'updatePieChart'}
                                data={updateStatus}
                                dataKey="count"
                                nameKey="type"
                                cx={'80%'}
                                innerRadius={20}
                                outerRadius={80}
                                label={<CustomizedLabel />}
                            >
                                {updateStatus.map(
                                    (entry: { type: string }, index) => (
                                        <Cell
                                            key={'updateChart' + index}
                                            fill={mapColor(entry.type)}
                                        />
                                    )
                                )}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </Card>
            </div>
        </Layout>
    );
};
