/* tslint:disable:no-any */
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { compose, graphql } from 'react-apollo';
import { push } from 'react-router-redux';
import {
    GraphQLMenu,
    MenuItem,
    GraphQLMenuDispatchProps,
    GraphQLMenuStateProps
} from './GraphQLMenu';
import { query } from './GraphQLMenuQuery';
import { toggleColapsed } from './GraphQLMenuAction';
import { ReduxState } from '../../store';
import { QueryProps } from 'react-apollo/types';

interface GraphQLMenuContainerProps {
    menuItems: Array<MenuItem>;
}
interface GraphQLMenuContainerProps1 {
    menu: { elements: Array<MenuItem> };
}

interface GraphQlMenuContainerMapProps {
    data: QueryProps & GraphQLMenuContainerProps1;
    ownProps: {};
}

export const mapStateToProps = (
    state: ReduxState,
    ownProps: GraphQLMenuContainerProps
): GraphQLMenuStateProps => ({
    isCollapsed: state.cia.menuReducer.isCollapsed,
    menuItems: ownProps.menuItems
});

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    ownProps: GraphQLMenuContainerProps
): GraphQLMenuDispatchProps => ({
    onNavigate: url => dispatch(push(url)),
    onToggleColappsed: () => dispatch(toggleColapsed())
});

export const mapProps = (props: GraphQlMenuContainerMapProps) => ({
    menuItems: props.data.loading ? [] : props.data.menu.elements
});

// graphql( query )( connect( map.., map... )(GraphQLMenu) )
export const GraphQLMenuContainer = compose<any>(
    graphql(query, {
        props: mapProps
    }),
    connect(mapStateToProps, mapDispatchToProps as any)
)(GraphQLMenu);

export default GraphQLMenuContainer;
