import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    AddContactDisplay,
    ContactInteractionActionProps
} from './AddContactDisplay';
import { setAddContactModalVisibility } from './AddContactReducer';
import { State } from '../../store';

export const mapStateToProps = (state: State) => ({
    contactClaims: state.cia.claimsReducer.claims.contact
});

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    {
        reportName,
        data,
        functionality
    }: { reportName: string; data: {}; functionality: string }
): ContactInteractionActionProps => {
    return {
        onShowDetails: () =>
            dispatch(
                setAddContactModalVisibility(
                    reportName,
                    data,
                    functionality,
                    true
                )
            )
    };
};

export const AddContactDisplayContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddContactDisplay);
