import * as React from 'react';
import { ConfigProvider, Pagination } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { convertBase64ToObjectWithFallback } from '../../util/UrlUtil';
import { defaultPagAndFilterArray } from './../../component/filter/FilterPanelContainer';
import { FilterProps } from './UrlPagination';

export interface PaginationDataProps {
    filter: string;
    numberOfItems: number;
    rowsPerPageOptions: Array<string>;
}

export interface PaginationFunctionProps {
    onSelectPage: (activePage: number, filter: FilterProps) => void;
    onChangeRowsPerPage: (rowsPerPage: number, filter: FilterProps) => void;
}

export const getSizeChangeFunction = (
    onchange: (x: number, y: FilterProps) => void,
    props: FilterProps
) => (page: number, pageSize: number) => onchange(pageSize, props);

export const PresentationalPagination: React.SFC<
    PaginationDataProps & PaginationFunctionProps
> = ({
    filter,
    numberOfItems,
    rowsPerPageOptions,
    onSelectPage,
    onChangeRowsPerPage
}) => {
    const propsFromUrl = convertBase64ToObjectWithFallback(
        filter,
        defaultPagAndFilterArray
    );

    return (
        <ConfigProvider locale={enUS}>
            <Pagination
                className="PaginationTest"
                style={{
                    paddingTop: '10px',
                    textAlign: 'right'
                }}
                showSizeChanger={true}
                onShowSizeChange={getSizeChangeFunction(
                    onChangeRowsPerPage,
                    propsFromUrl
                )}
                current={propsFromUrl.paginationOptions.activePage}
                total={numberOfItems}
                onChange={(page, pageSize) => onSelectPage(page, propsFromUrl)}
                pageSize={propsFromUrl.paginationOptions.rowsPerPage}
                pageSizeOptions={rowsPerPageOptions}
                showTotal={total => `Total ${total}`}
            />
        </ConfigProvider>
    );
};
