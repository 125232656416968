import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AddCertificateDisplay } from './AddCertificateDisplay';
import { setAddCertificateModalVisibility } from './AddCertificateReducer';
import { State } from '../../store';

export const mapStateToProps = (state: State) => ({
    certificateClaims: state.cia.claimsReducer.claims.certificate
});

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    reportName: {
        reportName: string;
    }
) => {
    return {
        onShowDetails: () =>
            dispatch(
                setAddCertificateModalVisibility(reportName.reportName, true)
            )
    };
};
export const AddCertificateDisplayContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCertificateDisplay);
