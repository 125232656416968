import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import {
    ColumnToggleInteractionAction,
    ColumnToggleModalPayload
} from './ColumnToggleReducer';

interface FilterBoxProps extends FilterBoxActions {
    reportName: string;
    columnsAvailable: ColumnProps[];
    visibleColumns: Immutable.Map<string, string[]>;
}

interface ColumnProps {
    key: string;
    name: string;
}

export interface FilterBoxActions {
    onToggleColumn: (
        data: string[]
    ) => ColumnToggleInteractionAction<ColumnToggleModalPayload>;
}

export class ColumnToggle extends React.Component<FilterBoxProps, never> {
    constructor(props: FilterBoxProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(checkedValues: string[]) {
        this.props.onToggleColumn(checkedValues);
    }

    render() {
        return (
            <Checkbox.Group
                style={{ width: '100%' }}
                onChange={this.onChange}
                defaultValue={this.props.visibleColumns.get(
                    this.props.reportName
                )}
            >
                <Row>
                    {this.props.columnsAvailable.map(
                        (column: ColumnProps, index: number) => {
                            return (
                                <Col span={6} key={index}>
                                    <Checkbox
                                        value={column.key}
                                        key={column.key}
                                    >
                                        {column.name}
                                    </Checkbox>
                                </Col>
                            );
                        }
                    )}
                </Row>
            </Checkbox.Group>
        );
    }
}
