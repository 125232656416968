import * as React from 'react';
import { orderBy } from 'lodash';
import { ContactsOutlined, FileOutlined, HomeOutlined, LeftCircleTwoTone, MailOutlined, RightCircleTwoTone } from '@ant-design/icons';
import { Layout, Menu, Tooltip, Button } from 'antd';
import './GraphQLMenu.css';
import { LocationDescriptorObject } from 'history';

export interface GraphQLMenuStateProps {
    isCollapsed?: boolean;
    menuItems: Array<MenuItem> | null;
    defaultSelectedKeys?: Array<string>;
    defaultOpenKeys?: Array<string>;
    mode?: 'vertical' | 'horizontal' | 'inline';
    theme?: 'dark' | 'light';
    inlineCollapsed?: boolean;
}

export interface GraphQLMenuDispatchProps {
    onNavigate: (url: string) => void;
    onToggleColappsed: React.MouseEventHandler<{}>;
}

export interface GraphQLMenuProps
    extends GraphQLMenuDispatchProps,
    GraphQLMenuStateProps { }

export interface MenuItem {
    title: string;
    description?: string | null;
    url?: string | null;
    priority: number;
    icon?: string;
    children?: Array<MenuItem> | null;
    __typename?: string;
}

export const GraphQLMenu: React.SFC<GraphQLMenuProps> = ({
    isCollapsed,
    menuItems,
    defaultSelectedKeys,
    defaultOpenKeys,
    mode = 'inline',
    theme = 'dark',
    inlineCollapsed = true,
    onNavigate,
    onToggleColappsed
}: GraphQLMenuProps) => (
    <Layout.Sider className="graphqlmenu-sider" collapsed={isCollapsed}>
        <div
            style={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <b
                style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    fontSize: '35px'
                }}
            >
                CIA
            </b>
        </div>
        <Menu
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={defaultOpenKeys}
            mode={mode}
            theme={theme}
            inlineCollapsed={inlineCollapsed}
            onSelect={onNavigateHelper(onNavigate)}
        >
            {transformToReact(menuItems ? menuItems : [])}
        </Menu>
        <Tooltip title="Contact Us" placement="top">
            <Button
                ghost={true}
                href="mailto:ecom.fs.certificates@mediamarktsaturn.com?Subject=CIA%20Issue: "
                icon={<MailOutlined />}
                shape="circle"
                style={{
                    border: 'none',
                    alignSelf: 'center'
                }}
            />
        </Tooltip>
        {isCollapsed ? <RightCircleTwoTone className="graphqlmenu-icon" onClick={onToggleColappsed} /> : <LeftCircleTwoTone className="graphqlmenu-icon" onClick={onToggleColappsed} />}
    </Layout.Sider>
);

const menuProperty = '__ANT_LAYOUT_SIDER';
GraphQLMenu[menuProperty] = true;

function transformToReact(data: Array<MenuItem>) {
    const mappedComponents: Array<{}> = [];

    orderBy(data, 'priority', 'desc').forEach(menuEntry => {
        if (menuEntry.children) {
            mappedComponents.push(
                <Menu.SubMenu
                    title={getTitleForMenuItem(menuEntry)}
                    key={menuEntry.title}
                >
                    {transformToReact(menuEntry.children)}
                </Menu.SubMenu>
            );
        } else {
            mappedComponents.push(
                <Menu.Item
                    className="graphqlmenu-menuitem"
                    id={`MenuItem__${menuEntry.title}`}
                    key={menuEntry.url === null ? undefined : menuEntry.url}
                >
                    {getTitleForMenuItem(menuEntry)}
                </Menu.Item>
            );
        }
    });
    return mappedComponents;
}

const getTitleForMenuItem = (menuItem: MenuItem) => (
    <span>
        {
            menuItem.title === "Home" ? <HomeOutlined /> :
                menuItem.title === "Certificates" ? <FileOutlined /> :
                    menuItem.title === "Contacts" ? <ContactsOutlined /> :
                        ''
        }

        <span>{menuItem.title}</span>
    </span>
);

const onNavigateHelper = (onNavigate: (url: String) => void) => (
    someEvent: LocationDescriptorObject
) => {
    let cual = '/';
    if (someEvent.key !== undefined) {
        cual = someEvent.key.substring(1);
    }
    someEvent.key !== undefined ? onNavigate(cual) : onNavigate('/');
};
