import { gql } from 'react-apollo';

export const query = gql`
    {
        menu {
            elements {
                title
                description
                url
                priority
                children {
                    title
                    description
                    url
                    priority
                }
                icon
            }
        }
    }
`;

export default query;
