/**
 * Created by geroe on 16.03.2016.
 */
/* tslint:disable:no-any */
import React from 'react';
import { MinusOutlined } from '@ant-design/icons';
import { Select, TreeSelect, Button, DatePicker } from 'antd';
import { FilterDataType } from './filterService/FilterSchema';
import { EditableTagGroup } from './widgets/EditableTagGroup';
import { InputAndCheck } from './widgets/InputAndCheck';
const Option = Select.Option;

var Reflux = require('reflux');

require('moment/locale/de');

/*global Reflux*/

/**
 * I'm sorry I have forgotten to document the class!
 */
export class FilterControl extends React.Component<any> {
    _changeStore: any;
    _removeAction: any;
    info: any;

    constructor(props: any) {
        super(props);
        this._getDefinitionByIndex = this._getDefinitionByIndex.bind(this);
        this._getOperatorByIndex = this._getOperatorByIndex.bind(this);
        this._changeStore = Reflux.createStore({
            handleChange(field: any, value: any) {
                this.trigger(field, value);
            }
        });
        if (this.props.changeStoreConnector) {
            this.props.changeStoreConnector.listenTo(this._changeStore);
        }

        this._removeAction = Reflux.createAction();
        if (this.props.removeStoreConnector) {
            this.props.removeStoreConnector.listenTo(this._removeAction);
        }
    }

    _buildValueField(type: any) {
        if (type.dataType === FilterDataType.DATETIME) {
            return (
                <DatePicker
                    value={this.props.value}
                    onChange={(e: any) =>
                        this._changeStore.handleChange('value', e)
                    }
                />
            );
        } else if (type.dataType === FilterDataType.BOOL) {
            const propsToSend = {
                onChange: (e: any) =>
                    this._changeStore.handleChange('value', e),
                value: this.props.value,
                typeOf: 'check'
            };
            return <InputAndCheck {...propsToSend} />;
        } else if (type.isList) {
            const propsToSend = {
                onChange: (e: any) =>
                    this._changeStore.handleChange('value', e),
                value: this.props.value
            };
            return <EditableTagGroup {...propsToSend} />;
        } else {
            const propsToSend = {
                onChange: (e: any) =>
                    this._changeStore.handleChange('value', e),
                value: this.props.value,
                typeOf: 'text'
            };
            return <InputAndCheck {...propsToSend} />;
        }
    }

    _removeFilter() {
        this._removeAction();
    }

    _getDefinitionByIndex(i: any) {
        const schema = this.props.schema;

        return schema && schema.definitions && schema.definitions[i];
    }

    _getOperatorByIndex(fieldIndex: any, opIndex: any) {
        const field = this._getDefinitionByIndex(fieldIndex);

        return field && field.operators && field.operators[opIndex];
    }

    _renderControl() {
        const schema = this.props.schema;
        const selectedFieldName = this.props.field;
        const selectedOperatorName = this.props.operator.name;

        const selectedField = schema.getDefinition(selectedFieldName);
        const selectedOperatorIndex = selectedField.operators.findIndex(
            (operator: any) => operator.name === selectedOperatorName
        );
        const changeField = (value: any) => {
            if (!schema.getDefinition(value).isComplex()) {
                this._changeStore.handleChange('field', value);
            } else {
                this.info(`selected complex field: ${value} (no update)`);
            }
        };
        const changeOperator = (value: any) => {
            this._changeStore.handleChange('operator', value);
        };
        const operatorIndex =
            selectedOperatorIndex >= 0 ? selectedOperatorIndex : 0;
        const selectedType = selectedField.operators[operatorIndex].type;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '5px'
                }}
            >
                <TreeSelect
                    style={{ width: 200 }}
                    onChange={changeField}
                    key="0"
                    value={selectedFieldName}
                    treeData={this.props.tree}
                    dropdownMatchSelectWidth={false}
                    showSearch={false}
                    treeDefaultExpandAll={true}
                    size="small"
                />
                <Select
                    style={{ width: 200 }}
                    onChange={changeOperator}
                    value={selectedOperatorName}
                    key="1"
                    size="small"
                >
                    {selectedField.operators.map((o: any) => {
                        return (
                            <Option key={''} value={o.name}>
                                {o.displayName}
                            </Option>
                        );
                    })}
                </Select>
                {this._buildValueField(selectedType)}
                <Button
                    key="2"
                    type="default" danger
                    icon={<MinusOutlined />}
                    size="small"
                    onClick={this._removeAction}
                />
            </div>
        );
    }

    render() {
        return this._renderControl();
    }
}
