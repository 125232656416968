/**
 * Created by geroe on 12.08.2016.
 */
/* tslint:disable:no-any */
import React from 'react';
import { FilterPanel } from './FilterPanel';
import { FilterAggregator } from './filterService/FilterAggregator';
import { GraphQLHelpers } from './graphqlService/GraphQLHelpers';
import { assign } from 'lodash';
import { castArray, filter, flow, map } from 'lodash/fp';
import { SimpleFilter } from './filterService/SimpleFilter';
import { Alert } from 'antd';

export const mapFilterArrayToSimpleFilters = flow(
    castArray,
    filter((afilter: any) => !!filter),
    map((afilter: any) => ({
        field: afilter._field,
        operator: afilter._operator,
        value: afilter._value
    })),
    map((afilter: any) => new SimpleFilter(afilter))
);

export class PresentationalFilterContainer extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {
            schema: null,
            filters: [],
            error: null,
            errorOpen: false
        };
        this._onApplyFilters = this._onApplyFilters.bind(this);
        this._handleErrorClose = this._handleErrorClose.bind(this);
    }

    async componentDidMount() {
        const { type, endpoint, client } = this.props as any;
        const helper = new GraphQLHelpers(endpoint, client);
        const schema = await helper.filterSchemaFromType(type);

        this.setState({ schema });
    }

    componentWillReceiveProps({ currentFilters }: any) {
        this.setState({
            filters: mapFilterArrayToSimpleFilters(currentFilters.filters)
        });
    }

    _onApplyFilters(filters: any) {
        const aggregate = new FilterAggregator({ filters });
        const validation = (this.state as any).schema.validateFilterAggregate(
            aggregate
        );

        if (!validation.isValid) {
            this._setErrorMsg(validation.errorMessage); // TODO
        } else {
            (this.props as any).push(
                assign({}, (this.props as any).currentFilters, { filters })
            );
        }
    }

    _setErrorMsg(msg: any) {
        this.setState({ error: msg, errorOpen: true });
    }

    _handleErrorClose() {
        this.setState({ errorOpen: false });
    }

    render() {
        const { filters = [], schema, errorOpen, error } = this.state as any;
        const self = this;

        if (schema !== null) {
            const propsToSend = { afterClose: this._handleErrorClose };
            return (
                <div>
                    <div style={{ marginBottom: '10px' }}>
                        {errorOpen ? (
                            <Alert
                                message={error}
                                type="error"
                                closable={true}
                                {...propsToSend}
                            />
                        ) : null}
                    </div>
                    <FilterPanel
                        schema={schema}
                        onApply={this._onApplyFilters}
                        onChange={(afilters: any) =>
                            self.setState({ filters: afilters })
                        }
                        filters={filters}
                    />
                </div>
            );
        } else {
            return <div>Loading...</div>;
        }
    }
}
