import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { compose, graphql, gql } from 'react-apollo';
import { SetClaimsActionState, setClaims } from './ClaimsReducer';

interface ClaimsProps extends ClaimsActionProps {
    data: {
        claims: {
            claims: {
                certificate: {
                    add: Array<string>;
                    edit: Array<string>;
                    refresh: Array<string>;
                    remove: Array<string>;
                    view: Array<string>;
                };
                contact: {
                    add: boolean;
                    edit: boolean;
                    remove: boolean;
                    view: boolean;
                };
            };
        };
    };
}

interface ClaimsActionProps {
    onRecievedClaims: (claims: SetClaimsActionState) => void;
}

export class Claims extends React.PureComponent<ClaimsProps> {
    componentWillReceiveProps(props: ClaimsProps) {
        if (props.data !== undefined) {
            this.props.onRecievedClaims(props.data.claims);
        }
    }
    render() {
        return false;
    }
}

const claimQuery = gql`
    query {
        claims {
            certificate {
                view
                edit
                remove
                refresh
                add
            }
            contact {
                view
                edit
                add
                remove
            }
        }
    }
`;

export const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
    onRecievedClaims: (claims: SetClaimsActionState) =>
        dispatch(setClaims(claims))
});

export const ClaimsContainer = compose(
    graphql(claimQuery),
    connect(null, mapDispatchToProps)
)(Claims);
