import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { setColumnsVisibility } from './ColumnToggleReducer';
import { ColumnToggle, FilterBoxActions } from './ColumnToggle';
import { State } from '../../store';

export interface MapStateProps {
    visibleColumns: Immutable.Map<string, string[]>;
}

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    { reportName }: { reportName: string }
): FilterBoxActions => ({
    onToggleColumn: (data: string[]) =>
        dispatch(setColumnsVisibility(reportName, data))
});

export const mapStateToProps = (state: State): MapStateProps => {
    return {
        visibleColumns: state.cia.columnToggleReducer.columnsVisible
    };
};

export const ColumnToggleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ColumnToggle);
