import { graphql, gql, compose, QueryProps } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { MakeDecisionView } from './MakeDecisionView';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import notification from 'antd/lib/notification';
import React from 'react';

const makeDecisionViewQuery = gql`
    query MakeDecisionQuery($token: UUID!) {
        views {
            certificate {
                certificateRenew(token: $token) {
                    domainName
                    type
                    expirationDate
                    salesLines
                }
            }
        }
    }
`;

const makeDecisionViewMutation = gql`
    mutation MakeDecisionMutation($token: UUID!, $renew: Boolean!) {
        certificate {
            renew(token: $token, renew: $renew) {
                domainName
                type
                expirationDate
                salesLines
                cherwellTicket
            }
        }
    }
`;

export interface Certificate {
    domainName: string;
    expirationDate: string;
    salesLines: string[];
    type: string;
}

export const handleLoading = (
    isLoading: boolean,
    data: {
        certificate: {
            certificateRenew: Certificate;
        };
    },
    field: string,
    loadingValue: null | undefined | Array<string> | string = ''
) =>
    isLoading || !data
        ? loadingValue
        : data.certificate.certificateRenew[field];

export const createMakeDecision = (
    token: string,
    decision: boolean,
    dispatch: Dispatch<{}>,
    mutate: Function
) => async () => {
    try {
        const { error, data } = await mutate({
            variables: {
                token,
                renew: decision
            }
        });

        if (error) {
            throw error;
        } else {
            if (decision) {
                let ticketNotification: React.ReactNode;
                // the following placeholder will be replaced with the actual cherwellURL
                // when the docker container boots (in docker/scripts/entrypoint.sh)
                const cherwellApplicationURL =
                    'XXXplaceholder_for_CherwellApplicationURLXXX';

                const caseNumber = data.certificate.renew.cherwellTicket;
                if (cherwellApplicationURL.indexOf('XXXplaceholder_') >= 0 ||
                        !cherwellApplicationURL
                ) {
                    ticketNotification = 'Your case number is ' + caseNumber;
                } else {
                    const ticketApplicationUrl = cherwellApplicationURL + 'incident/';
                    const ticketUrl: string = ticketApplicationUrl + caseNumber;
                    ticketNotification = (
                        <a href={ticketUrl} target="_blank">
                            Go to ticket {caseNumber}
                        </a>
                    );
                }
                notification.success({
                    placement: 'topLeft',
                    duration: 0,
                    style: {
                        marginTop: 25,
                        position: 'absolute',
                        left: 96,
                        width: 450,
                        border: '1px solid #52c41a'
                    },
                    message: 'Decision made successful',
                    description: (
                        <div>
                            Your decision to renew was persisted. <br />
                            {ticketNotification}
                        </div>
                    )
                });
            } else {
                notification.success({
                    message: 'Decision made successful',
                    description: `Your decision to expire was persisted.`
                });
            }
            dispatch(push('/'));
        }
    } catch (error) {
        notification.error({
            duration: 0,
            message: 'An Error ocurred',
            description: error.message
        });
    }
};

export interface OwnProps {
    error: {};
    history: {};
    loading: boolean;
    location: {};
    match: {
        path: string;
        url: string;
        params: {
            token: string;
        };
    };
    mutate: Function;
    token: string;
    views: {
        certificate: {
            certificateRenew: Certificate;
        };
    };
}

export const mapStateToProps = (
    state: {},
    { views, error, loading }: OwnProps
) => ({
    domainName: handleLoading(loading, views, 'domainName'),
    type: handleLoading(loading, views, 'type'),
    expirationDate: new Date(
        handleLoading(loading, views, 'expirationDate', undefined)
    ).toLocaleDateString(),
    salesLines: handleLoading(loading, views, 'salesLines', [])
});

export const mapDispatchToProps = (
    dispatch: Dispatch<string>,
    { mutate, token }: OwnProps
) => {
    return {
        onRenew: createMakeDecision(token, true, dispatch, mutate),
        onExpire: createMakeDecision(token, false, dispatch, mutate)
    };
};

export const mapOptions = ({ match: { params: { token } } }: OptionsInput) => ({
    fetchPolicy: 'network-only',
    variables: { token }
});

export interface MapPropCompleteDefinition {
    data: QueryProps & MapPropDefinition;
    ownProps: OptionsInput;
}

interface OptionsInput {
    match: {
        params: {
            token: string;
        };
    };
}

interface MapPropDefinition {
    views: {
        certificate: {
            certificateRenew: Certificate;
        };
    };
}

export const mapProps = ({
    ownProps: { match: { params: { token } } },
    data: { views, error, loading }
}: MapPropCompleteDefinition) => ({
    token,
    views,
    error,
    loading
});

export const MakeDecisionViewContainer = compose(
    withRouter,
    graphql(makeDecisionViewQuery, {
        options: mapOptions,
        props: mapProps
    }),
    graphql(makeDecisionViewMutation),
    connect(mapStateToProps, mapDispatchToProps)
)(MakeDecisionView);

export default MakeDecisionViewContainer;
