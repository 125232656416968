import * as React from 'react';
import { AddCertificateForm } from './AddCertificateForm';
import { ContactDataProps } from '../certificateInteraction/DetailsModal';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

export interface AddCertificateModalDataProps {
    visible: boolean;
    certificateTypes: string[];
    contacts: {};
}

export interface AddCertificateModalActionProps {
    onCancel: () => void;
    onSubmit: (values: FormValueProps, form: WrappedFormUtils | null) => Promise<boolean>;
}

export interface AddCertificateModalProps
    extends AddCertificateModalDataProps,
        AddCertificateModalActionProps {}

export interface AddCertificateModalState {
    // tslint:disable-next-line
    formRef: any;
}

export interface ErrorProps {
    field: string;
    message: string;
}

export interface FormErrorProps {
    contacts: [ErrorProps];
    domainName: [ErrorProps];
    salesLines: [ErrorProps];
    type: [ErrorProps];
}

interface FormValueProps {
    contacts: Array<string>;
    domainName: string;
    salesLines: Array<string>;
    type: string;
    port: number;
}

export class AddCertificateModal extends React.Component<
    AddCertificateModalProps,
    AddCertificateModalState
> {
    constructor(props: AddCertificateModalProps) {
        super(props);
        this.state = { formRef: null };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = () => {
        const form = this.state.formRef.props.form;
        form.validateFields((err: FormErrorProps, values: FormValueProps) => {
            if (err) {
                return;
            }
            const e = values.contacts;
            const contacts = this.props.contacts;
            var namedContacts: Array<string> = [];
            for (const i of e) {
                for (const j in contacts) {
                    if (i === (contacts[j] as ContactDataProps).id) {
                        namedContacts.push(contacts[j].name);
                    }
                }
            }
            values.contacts = namedContacts;
            this.props.onSubmit(values, form);
        });
    }

    saveFormRef = (formRef: Symbol) => {
        this.setState({ formRef: formRef });
    }

    render() {
        const { visible, onCancel, certificateTypes, contacts } = this.props;
        return (
            <AddCertificateForm
                wrappedComponentRef={this.saveFormRef}
                visible={visible}
                onSubmit={this.handleSubmit}
                onCancel={onCancel}
                certificateTypes={certificateTypes}
                contacts={contacts}
            />
        );
    }
}
