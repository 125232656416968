import { Action } from 'redux';
// import { Map } from 'immutable';

export interface ClaimsRecievedAction<T> extends Action {
    payload: T;
}

export interface ClaimsRecievedPayload {
    claims: {};
}

export const setClaims = (
    claims: SetClaimsActionState
): ClaimsRecievedAction<ClaimsRecievedPayload> => ({
    type: ClaimsRecievedActions.SetClaims,
    payload: {
        claims
    }
});

export const defaultState: SetClaimsActionState = {
    claims: {
        certificate: {
            add: [''],
            edit: [''],
            refresh: [''],
            remove: [''],
            view: ['']
        },
        contact: {
            add: false,
            edit: false,
            remove: false,
            view: false
        }
    }
};

export interface CertificateClaims {
    add: Array<string>;
    edit: Array<string>;
    refresh: Array<string>;
    remove: Array<string>;
    view: Array<string>;
}

export interface ContactClaims {
    add: boolean;
    edit: boolean;
    remove: boolean;
    view: boolean;
}

export interface SetClaimsActionState {
    claims: {
        certificate: CertificateClaims;
        contact: ContactClaims;
    };
}

export enum ClaimsRecievedActions {
    SetClaims = 'ClaimsRecieved: setClaims'
}

export const SetClaimsReducer = (
    state: SetClaimsActionState = defaultState,
    action: ClaimsRecievedAction<ClaimsRecievedPayload>
) => {
    switch (action.type) {
        case ClaimsRecievedActions.SetClaims:
            const payload = action.payload as ClaimsRecievedPayload;
            return { ...state, claims: payload.claims };
        default:
            return state;
    }
};
