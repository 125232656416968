import { Action } from 'redux';
import { Map } from 'immutable';
import { AddContactResult } from './AddContactModalContainer';

export interface AddContactInteractionAction<T> extends Action {
    payload: T;
}

export interface ShowAddContactModalPayload {
    reportName: string;
    visible: boolean;
    data: {};
    functionality: string;
}

export const setAddContactModalVisibility = (
    reportName: string,
    data: {},
    functionality: string,
    visible: boolean
): AddContactInteractionAction<ShowAddContactModalPayload> => ({
    type: AddContactInteractionActions.SetDetailsModalVisibilityOnPage,
    payload: {
        visible,
        reportName,
        data,
        functionality
    }
});

export const defaultState: AddContactActionState = {
    visibleOnReport: Map(),
    activeRowPerReport: {
        email: '',
        id: '',
        name: '',
        phoneNumber: '',
        username: ''
    },
    functionalityModal: ''
};

export interface AddContactActionState {
    visibleOnReport: Map<string, boolean>;
    activeRowPerReport: AddContactResult;
    functionalityModal: string;
}

export enum AddContactInteractionActions {
    SetDetailsModalVisibilityOnPage = 'AddContactInteraction: setAddContactModalVisibilityOnPage'
}

export const AddContactReducer = (
    state: AddContactActionState = defaultState,
    action: AddContactInteractionAction<ShowAddContactModalPayload>
) => {
    switch (action.type) {
        case AddContactInteractionActions.SetDetailsModalVisibilityOnPage:
            const payload = action.payload as ShowAddContactModalPayload;
            return {
                ...state,
                activeRowPerReport: {
                    ...action.payload.data
                },
                visibleOnReport: state.visibleOnReport.set(
                    payload.reportName,
                    payload.visible
                ),
                functionalityModal: payload.functionality
            };
        default:
            return state;
    }
};
