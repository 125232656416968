import { SubTranslationObject } from 'react-redux-i18n';

export const de: SubTranslationObject = {
    WelcomeView: {
        title: 'Willkommen bei CIA - Zertifikatsverwaltung'
    },
    FileNotFoundView: {
        text: 'Die angeforderte Seite existiert nicht!'
    }
};
