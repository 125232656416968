import * as React from 'react';
import {
    Row,
    Col,
    Table,
    Input,
    Select,
    DatePicker,
    Checkbox,
    message,
    InputNumber
} from 'antd';
import { RowData, ContactDataProps } from './DetailsModal';
import { Moment } from 'moment';
import { FilterContacts } from '../addCertificateInteraction/AddCertificateForm';
var moment = require('moment');
const Option = Select.Option;

export interface InfoProps {
    rowData: RowData;
    editOrNot: boolean;
    certificateTypes: string[];
    contacts: [ContactDataProps];
    handleStateChange: (obj: {}) => void;
}

interface InfoState { }

export interface ContactProps {
    id: string;
    email: string;
    name: string;
    phone: string;
    userName: string;
}

const SalesLines = [
    'OTHER',
    'MM_DE',
    'SE_DE',
    'MM_AT',
    'SE_AT',
    'MM_NL',
    'SE_NL',
    'MM_BE',
    'SE_BE',
    'MM_TR',
    'SE_TR',
    'MM_PL',
    'MM_CH',
    'MM_ES',
    'MM_PT',
    'MM_RU',
    'MM_SE',
    'MM_HU',
    'MM_GR',
    'SE_LU'
];

const EditPermissions = {
    domainName: true,
    type: true,
    status: false,
    cherwellTicket: true,
    allowedToExpire: true,
    expirationDate: true,
    issuedDate: true,
    lastChangeDate: false,
    comment: true,
    lastUpdateAttempt: false,
    lastCompleteUpdateRun: false,
    editor: false,
    salesLines: true,
    contacts: true,
    port: true,
    selfSigned: true
};

export interface RowMakerProps {
    title: string;
    edit: React.ReactElement;
    // tslint:disable-next-line
    label: any;
    editMode: boolean;
    modifyAllowed: boolean;
    oneRow: boolean;
}

export const RowMaker = ({
    title,
    edit,
    label,
    editMode,
    modifyAllowed,
    oneRow
}: RowMakerProps) => {
    let whichComponent = label;
    if (editMode && modifyAllowed) {
        whichComponent = edit;
    }
    if (oneRow) {
        return whichComponent;
    }
    return (
        <Row style={{ marginBottom: 2 }}>
            <Col span={8}>{title}</Col>
            <Col span={16}>{whichComponent}</Col>
        </Row>
    );
};

const dateFormatter = (date: Moment) => {
    if (date === undefined) {
        return date;
    } else {
        return date.format('DD[/]MM[/]YYYY, h:mm:ss a');
    }
};

export class DetailsModalInfo extends React.Component<InfoProps, InfoState> {
    constructor(props: InfoProps) {
        super(props);
    }

    // tslint:disable-next-line
    handleOnChange = (e: any, key: string) => {
        const handleStateChange = this.props.handleStateChange;
        if (key === 'salesLines') {
            var obj = {};
            obj[key] = e;
            handleStateChange(obj);
        } else if (key === 'contacts') {
            const contacts = this.props.contacts;
            var namedContacts: Array<string> = [];
            for (const i of e) {
                for (const j in contacts) {
                    if (i === contacts[j].id) {
                        namedContacts.push(contacts[j].name);
                    }
                }
            }
            var obj2 = {};
            obj2[key] = namedContacts;
            if (e.length !== 0) {
                handleStateChange(obj2);
            } else {
                message.error('There must be at least 1 contact!');
            }
        } else if (key === 'allowedToExpire' || key === 'selfSigned') {
            var obj3 = {};
            obj3[key] = e.target.checked;
            handleStateChange(obj3);
        } else if (key === 'type') {
            var obj4 = {};
            obj4[key] = e;
            handleStateChange(obj4);
        } else if (key.indexOf('Date') > 0) {
            var obj5 = {};
            if (e === null) {
                obj5[key] = e;
            } else {
                obj5[key] = e.format();
            }
            handleStateChange(obj5);
        } else if (key === 'port') {
            var obj6 = {};
            if (e !== undefined) {
                obj6[key] = e;
                handleStateChange(obj6);
            } else {
                obj6[key] = 443;
                handleStateChange(obj6);
                message.warning('Default Port (443) will be set!');
            }
        } else {
            var obj7 = {};
            obj7[key] = e.target.value;
            handleStateChange(obj7);
        }
    };

    render() {
        const { rowData, editOrNot, contacts, certificateTypes } = this.props;
        const cleanedContacts = rowData.contactData.map(contact => {
            return contact._id;
        });
        const emptyExpirationDate =
            rowData.expirationDate === null
                ? undefined
                : moment(rowData.expirationDate);
        const emptyIssueDate =
            rowData.certificateDetails.issuedDate === null
                ? undefined
                : moment(rowData.certificateDetails.issuedDate);
        const emptyLastChange =
            rowData.certificateDetails.lastChangeDate === null
                ? undefined
                : moment(rowData.certificateDetails.lastChangeDate);
        const emptyLastUpdateRun =
            rowData.certificateDetails.lastCompleteUpdateRun === null
                ? undefined
                : moment(rowData.certificateDetails.lastCompleteUpdateRun);

        return (
            <span>
                <b>Header Details:</b>
                <br />
                <RowMaker
                    title={'Domain Name:'}
                    edit={<Input
                        size="small"
                        defaultValue={rowData.domainName}
                        onChange={e => this.handleOnChange(e, 'domainName')} />}
                    label={rowData.header.domainName}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.domainName} oneRow={false} />
                <RowMaker
                    title={'Type:'}
                    edit={
                        <Select
                            placeholder="Please select a type"
                            style={{ width: '100%' }}
                            defaultValue={rowData.type}
                            size="small"
                            onChange={e => this.handleOnChange(e, 'type')}
                        >
                            {certificateTypes.map((type: string) => {
                                return (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                );
                            })}
                        </Select>
                    }
                    label={rowData.header.type}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.type}
                    oneRow={false}
                />
                <RowMaker
                    title={'Current Status:'}
                    edit={<Input size="small" defaultValue={rowData.status} />}
                    label={rowData.header.status}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.status}
                    oneRow={false}
                />
                <RowMaker
                    title={'Cherwell:'}
                    edit={
                        <Input
                            size="small"
                            defaultValue={
                                rowData.cherwellTicket !== null
                                    ? rowData.cherwellTicket.toString()
                                    : 'undefined'
                            }
                            onChange={e =>
                                this.handleOnChange(e, 'cherwellTicket')
                            }
                        />
                    }
                    label={rowData.header.cherwellTicket}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.cherwellTicket}
                    oneRow={false}
                />
                <RowMaker
                    title={'Allowed To Expire:'}
                    edit={
                        <Checkbox
                            defaultChecked={rowData.allowedToExpire}
                            onChange={e =>
                                this.handleOnChange(e, 'allowedToExpire')
                            }
                        />
                    }
                    label={
                        rowData.header.allowedToExpire === null
                            ? 'false'
                            : '' + rowData.header.allowedToExpire
                    }
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.allowedToExpire}
                    oneRow={false}
                />
                <RowMaker
                    title={'Expiration Date:'}
                    edit={
                        <DatePicker
                            showTime={true}
                            format={'DD[/]MM[/]YYYY, h:mm:ss a'}
                            size="small"
                            style={{ width: '100%' }}
                            defaultValue={emptyExpirationDate}
                            onChange={(date, dateString) =>
                                this.handleOnChange(date, 'expirationDate')
                            }
                        />
                    }
                    label={dateFormatter(emptyExpirationDate)}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.expirationDate}
                    oneRow={false}
                />
                <RowMaker
                    title={'Port:'}
                    edit={
                        <InputNumber
                            min={0}
                            max={65535}
                            style={{ width: '100%' }}
                            size="small"
                            defaultValue={rowData.port}
                            onChange={e => this.handleOnChange(e, 'port')}
                        />
                    }
                    label={
                        rowData.header.certPort === 443 ||
                            rowData.header.certPort === undefined ||
                            rowData.header.certPort === null
                            ? '443 (Default Port)'
                            : rowData.header.certPort
                    }
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.port}
                    oneRow={false}
                />
                <RowMaker
                    title={'Self Signed:'}
                    edit={
                        <Checkbox
                            defaultChecked={rowData.header.selfSigned}
                            onChange={e => this.handleOnChange(e, 'selfSigned')}
                        />
                    }
                    label={
                        rowData.header.selfSigned === null
                            ? 'false'
                            : '' + rowData.header.selfSigned
                    }
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.selfSigned}
                    oneRow={false}
                />
                <br />
                <b>Certificate Details:</b>
                <br />
                <RowMaker
                    title={'Issued Date:'}
                    edit={
                        <DatePicker
                            showTime={true}
                            format={'DD[/]MM[/]YYYY, h:mm:ss a'}
                            size="small"
                            style={{ width: '100%' }}
                            defaultValue={emptyIssueDate}
                            onChange={(date, dateString) =>
                                this.handleOnChange(date, 'issuedDate')
                            }
                        />
                    }
                    label={dateFormatter(emptyIssueDate)}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.issuedDate}
                    oneRow={false}
                />
                <RowMaker
                    title={'Last Change Date:'}
                    edit={
                        <DatePicker
                            showTime={true}
                            format={'DD[/]MM[/]YYYY, h:mm:ss a'}
                            size="small"
                            style={{ width: '100%' }}
                            defaultValue={emptyLastChange}
                        />
                    }
                    label={dateFormatter(emptyLastChange)}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.lastChangeDate}
                    oneRow={false}
                />
                <RowMaker
                    title={'Comment:'}
                    edit={
                        <Input
                            size="small"
                            defaultValue={rowData.certificateDetails.comment}
                            onChange={e => this.handleOnChange(e, 'comment')}
                        />
                    }
                    label={rowData.certificateDetails.comment}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.comment}
                    oneRow={false}
                />
                <RowMaker
                    title={'Last Update Attempt:'}
                    edit={
                        <Input
                            size="small"
                            defaultValue={
                                rowData.certificateDetails.lastUpdateAttempt
                            }
                        />
                    }
                    label={rowData.certificateDetails.lastUpdateAttempt}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.lastUpdateAttempt}
                    oneRow={false}
                />
                <RowMaker
                    title={'Last Complete Update Run:'}
                    edit={
                        <DatePicker
                            size="small"
                            style={{ width: '100%' }}
                            defaultValue={emptyLastUpdateRun}
                        />
                    }
                    label={dateFormatter(emptyLastUpdateRun)}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.lastCompleteUpdateRun}
                    oneRow={false}
                />
                <RowMaker
                    title={'Editor:'}
                    edit={
                        <Input
                            size="small"
                            defaultValue={rowData.certificateDetails.editor}
                        />
                    }
                    label={rowData.certificateDetails.editor}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.editor}
                    oneRow={false}
                />
                <RowMaker
                    title={'Sales Lines:'}
                    edit={
                        <Select
                            mode="multiple"
                            placeholder="Please select the Sales Lines"
                            size="small"
                            style={{ width: '100%' }}
                            defaultValue={rowData.certificateDetails.salesLines}
                            onChange={e => this.handleOnChange(e, 'salesLines')}
                        >
                            {SalesLines.map((lines: string) => {
                                return (
                                    <Option key={lines} value={lines}>
                                        {lines}
                                    </Option>
                                );
                            })}
                        </Select>
                    }
                    label={rowData.certificateDetails.salesLines.map(
                        (lines, index) => {
                            return index + 1 + '.' + lines + ' ';
                        }
                    )}
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.salesLines}
                    oneRow={false}
                />
                <br />
                <b>Contact Information:</b>
                <RowMaker
                    title={''}
                    edit={
                        <Select
                            mode="multiple"
                            placeholder="Select the contacts"
                            style={{ width: '100%' }}
                            defaultValue={cleanedContacts}
                            size="small"
                            onChange={e => this.handleOnChange(e, 'contacts')}
                            filterOption={(input, option) =>
                                FilterContacts(input, option)
                            }
                        >
                            {contacts.map((type: ContactDataProps) => {
                                return (
                                    <Option value={type.id} key={type.name}>
                                        <div>
                                            {type.name + ' / ' + type.username}
                                        </div>
                                        <div>
                                            {type.email +
                                                ' / ' +
                                                type.phoneNumber}
                                        </div>
                                    </Option>
                                );
                            })}
                        </Select>
                    }
                    label={
                        <Table
                            size="small"
                            rowKey={(record: ContactDataProps) => record._id}
                            dataSource={rowData.contactData}
                            columns={columns}
                            pagination={false}
                        />
                    }
                    editMode={editOrNot}
                    modifyAllowed={EditPermissions.contacts}
                    oneRow={true}
                />
            </span>
        );
    }
}

const columns = [
    {
        title: 'Username',
        dataIndex: 'userName',
        key: 'userName'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: 'Phone No.',
        dataIndex: 'phone',
        key: 'phone'
    }
];
