import { Action } from 'redux';
import { Map } from 'immutable';

export interface AddCertificateInteractionAction<T> extends Action {
    payload: T;
}

export interface ShowAddCertificateModalPayload {
    reportName: string;
    visible: boolean;
}

export const setAddCertificateModalVisibility = (
    reportName: string,
    visible: boolean
): AddCertificateInteractionAction<ShowAddCertificateModalPayload> => ({
    type: AddCertificateInteractionActions.SetDetailsModalVisibilityOnPage,
    payload: {
        visible,
        reportName
    }
});

export const defaultState: AddCertificateActionState = {
    visibleOnReport: Map()
};

export interface AddCertificateActionState {
    visibleOnReport: Map<string, boolean>;
}

export enum AddCertificateInteractionActions {
    SetDetailsModalVisibilityOnPage = 'AddCertificateInteraction: setAddCertificateModalVisibilityOnPage'
}

export const AddCertificateReducer = (
    state: AddCertificateActionState = defaultState,
    action: AddCertificateInteractionAction<ShowAddCertificateModalPayload>
) => {
    switch (action.type) {
        case AddCertificateInteractionActions.SetDetailsModalVisibilityOnPage:
            const payload = action.payload as ShowAddCertificateModalPayload;
            return {
                ...state,
                visibleOnReport: state.visibleOnReport.set(
                    payload.reportName,
                    payload.visible
                )
            };
        default:
            return state;
    }
};
