import * as React from 'react';
import {PlayCircleOutlined} from '@ant-design/icons';
import {Button, Select, message} from 'antd';
import {MutationFunc, ApolloError} from 'react-apollo';
const Option = Select.Option;

interface CertificateRunProps {
    claims: string[];
    mutate: MutationFunc<boolean, {certType: string}>;
}

interface CertificateRunState {
    type: string;
    running: boolean;
}

export class CertificateRun extends React.Component<
    CertificateRunProps,
    CertificateRunState
> {
    constructor(props: CertificateRunProps) {
        super(props);
        this.state = {
            type: '',
            running: false
        };
    }

    handleSelect = (type: string) => {
        this.setState({type: type});
    };

    handleRun = async () => {
        if (this.state.type === '') {
            message.error('Certificate Type must be selected first!');
        } else if (this.props.claims.indexOf(this.state.type) === -1) {
            message.error(
                'User not allowed to trigger this Certificate Type run!'
            );
        } else {
            this.setState({running: true});
            try {
                await this.props.mutate({
                    variables: {certType: this.state.type}
                });
                this.setState({running: false});
                message.success(
                    `Successfully triggered the run for "${this.state.type
                    }" certificate types!`
                );
            } catch (error) {
                this.setState({running: false});
                if (error.graphQLErrors) {
                    const apolloError = error as ApolloError;
                    for (const err of apolloError.graphQLErrors) {
                        message.error(err.message);
                    }
                }
            }
        }
    };

    render() {
        if (this.props.claims.length > 0) {
            return (
                <span>
                    <b>Trigger Certificate Run: </b>
                    <Select
                        className={'certificateTypeSelect'}
                        dropdownMatchSelectWidth={false}
                        style={{width: '150px', marginRight: '1px'}}
                        placeholder={'Certificate Type'}
                        onChange={(e: string) => this.handleSelect(e)}
                    >
                        {this.props.claims.map((claim: string) => (
                            <Option key={claim} value={claim}>
                                {claim}
                            </Option>
                        ))}
                    </Select>
                    <Button
                        className={'refreshButton'}
                        icon={<PlayCircleOutlined />}
                        onClick={this.handleRun}
                        loading={this.state.running}
                    >
                        Run
                    </Button>
                </span>
            );
        } else {
            return null;
        }
    }
}
