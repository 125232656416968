/**
 * Created by geroe on 19.04.2016.
 */
// import { mixin, TLoggable } from 'fifa-javascript-utilities/GeneralUtils';
/* tslint:disable:no-any */
import { assert, isArrayOf } from './utils';
import { every, identity } from 'lodash';

export class ValidationMessage {
    // mixin(null, TLoggable) {

    _isValid: any;
    _errorMessage: any;
    _value: any;

    static reduceMessages(messages: any, returnValue: any = null) {
        assert(
            isArrayOf(messages, ValidationMessage),
            'need Array of ValidationMessage as input'
        );

        return new ValidationMessage({
            isValid: every(messages, (m: any) => m.isValid),
            errorMessage: messages
                .filter((m: any) => !m.isValid)
                .map((m: any) => m.errorMessage)
                .filter(identity)
                .join('; \n'),
            value: returnValue || messages.map((m: any) => m.value)
        });
    }

    constructor({ isValid, errorMessage, value }: any) {
        assert(typeof isValid === 'boolean', 'isValid must be set');
        this._isValid = isValid;
        this._errorMessage = errorMessage;
        this._value = value;
    }

    get isValid() {
        return this._isValid;
    }
    get errorMessage() {
        return this._errorMessage;
    }
    get value() {
        return this._value;
    }

    addMessagePrefix(prefix: any) {
        return new ValidationMessage({
            isValid: this._isValid,
            errorMessage: `${prefix} ${this._errorMessage}`,
            value: this._value
        });
    }
}
