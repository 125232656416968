import * as React from 'react';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
    AddContactInteractionAction,
    ShowAddContactModalPayload
} from './AddContactReducer';
import { ContactClaims } from '../claims/ClaimsReducer';

export interface ContactInteractionStateProps {
    functionality: string;
    reportName: string;
    contactClaims: ContactClaims;
}

export interface ContactInteractionActionProps {
    onShowDetails: () => AddContactInteractionAction<
        ShowAddContactModalPayload
    >;
}

interface ContactInteractionProps
    extends ContactInteractionStateProps,
    ContactInteractionActionProps { }

export const AddContactDisplay: React.SFC<ContactInteractionProps> = ({
    onShowDetails,
    functionality,
    contactClaims
}) => {
    if (functionality === 'ADD' && contactClaims.add) {
        return (
            <Button onClick={onShowDetails} icon={<UserOutlined />}>
                Create New Contact
            </Button>
        );
    } else if (functionality === 'EDIT' && contactClaims.edit) {
        return <Button onClick={onShowDetails} icon={<EditOutlined />} />;
    } else if (functionality === 'REMOVE' && contactClaims.remove) {
        return <Button onClick={onShowDetails} type="default" danger icon={<DeleteOutlined />} />;
    } else {
        return <Button style={{ display: 'none' }} />;
    }
};
