import { connect } from 'react-redux';
import { State } from '../../store';
import { CertificateRun } from './CertificateRun';
import { gql, graphql, compose } from 'react-apollo';

const triggerCertificateRun = gql`
    mutation TriggerRun($certType: String!) {
        certificate {
                refresh(certType: $certType)
            }
    }
`;

export const mapStateToProps = (state: State) => ({
    claims: state.cia.claimsReducer.claims.certificate.refresh
});

export const CertificateRunContainer = compose(
    graphql(triggerCertificateRun),
    connect(mapStateToProps)
)(CertificateRun);
