/**
 * Created by geroe on 19.04.2016.
 */
/* tslint:disable:no-any */
import { some } from 'lodash';

export const assert = (cond: any, msg: any) => {
    if (!cond) {
        throw new Error(msg);
    }
};
export const isArrayOf = (array: any, clazz: any) =>
    !!(
        array &&
        array instanceof Array &&
        !some(array, e => !(e instanceof clazz))
    );
