import { gql } from 'react-apollo';

export const deleteCertificateMutation = gql`
    mutation($id: SaveStringType!) {
        certificate {
            remove(id: $id) {
                id
            }
        }
    }
`;

export const editCertificateMutation = gql`
    mutation(
        $id: ID!
        $domainName: SaveStringType
        $contacts: [SaveStringType!]
        $type: CertificateTypeEnum
        $salesLines: [SalesLineEnum!]
        $status: SaveStringType
        $history: [HistoryEntryArgumentsType!]
        $cherwellTicket: Int
        $allowedToExpire: Boolean
        $expirationDate: DateTime
        $serialNumber: SaveStringType
        $issuedDate: DateTime
        $lastUpdateRunDate: DateTime
        $lastUpdateAttempt: SaveStringType
        $comment: SaveStringType
        $certPassword: SaveStringType
        $owner: ownerArgumentsType
        $issuer: IssuerArgumentsType
        $token: UUID
        $port: Int
        $selfSigned: Boolean
    ) {
        certificate {
            edit(
                id: $id
                domainName: $domainName
                contacts: $contacts
                type: $type
                salesLines: $salesLines
                status: $status
                history: $history
                cherwellTicket: $cherwellTicket
                allowedToExpire: $allowedToExpire
                expirationDate: $expirationDate
                serialNumber: $serialNumber
                issuedDate: $issuedDate
                lastUpdateRunDate: $lastUpdateRunDate
                lastUpdateAttempt: $lastUpdateAttempt
                comment: $comment
                certPassword: $certPassword
                owner: $owner
                issuer: $issuer
                token: $token
                certPort: $port
                selfSigned: $selfSigned
            ) {
                header {
                    id
                    domainName
                    type
                    status
                    cherwellTicket
                    allowedToExpire
                    expirationDate
                    certPort
                    selfSigned
                }
                certificateDetails {
                    serialNumber
                    issuedDate
                    lastChangeDate
                    lastUpdateRunDate
                    lastUpdateAttempt
                    comment
                    oldCherwellTicket
                    salesLines
                    editor
                }
                contactInformation {
                    id
                    name
                    username
                    email
                    phoneNumber
                }
            }
        }
    }
`;
