import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { ApolloProvider } from 'react-apollo';
import { createHashHistory } from 'history';
import {
    initialize as initializeReduxAndApollo,
    reduxStore,
    apolloClient
} from './gui/store';
import 'antd/dist/antd.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

const history = createHashHistory();

initializeReduxAndApollo({
    history,
    useReduxDevtoolsExtension: !!window.__REDUX_DEVTOOLS_EXTENSION__
});

ReactDOM.render(
    <ApolloProvider store={reduxStore} client={apolloClient}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </ApolloProvider>,
    document.getElementById('root') as HTMLElement
);
registerServiceWorker();
