import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
    MutationFunc,
    compose,
    graphql,
    ApolloError,
    withApollo,
    ApolloClient,
    OptionProps
} from 'react-apollo';
import {message} from 'antd';
import {State} from '../../store';
import {setDetailsModalVisibility} from './CertificateIntReducer';
import {
    DetailsModal,
    CommentModalActionProps,
    CommentModalDataProps,
    RowData,
    ContactDataProps
} from './DetailsModal';
import {ContactTableViewQuery} from '../../view/contactTable/details/ContactTableViewContainer';
import {
    editCertificateMutation,
    deleteCertificateMutation
} from './DetailsModalQueries';
import {AddContactResult} from '../addContactInteraction/AddContactModalContainer';

export interface DeleteCertificateVariables {
    id: string;
}

export interface EditCertificateVariables {
    id: string;
    domainName: string | null;
    type: string | null;
    status: string | null;
    cherwellTicket: number | null;
    allowedToExpire: boolean | null;
    expirationDate: string | null;
    issuedDate: string | null;
    lastChangeDate: string | null;
    comment: string | null;
    lastUpdateAttempt: string | null;
    lastCompleteUpdateRun: string | null;
    editor: string | null;
    salesLines: [string] | null;
    contacts: [ContactDataProps] | null;
    port: number | null;
    selfSigned: boolean | null;
}

export interface CommentModalContainerProps {
    reportName: string;
}

interface QueryDataProps {
    views: {
        contacts: {
            contactOverview: {
                elements: [AddContactResult];
            };
        };
    };
}

export const DefaultRowData: RowData = {
    domainName: '',
    type: '',
    status: '',
    cherwellTicket: 0,
    allowedToExpire: false,
    expirationDate: '',
    port: 0,
    certificateDetails: {
        cherwellHistory: 0,
        comment: '',
        editor: '',
        issuedDate: '',
        lastChangeDate: '',
        lastCompleteUpdateRun: '',
        lastUpdateAttempt: '',
        salesLines: [''],
        serialNo: ''
    },
    contactData: [
        {
            _id: '',
            email: '',
            name: '',
            phoneNumber: '',
            username: ''
        }
    ],
    header: {
        allowedToExpire: false,
        status: '',
        domainName: '',
        expirationDate: '',
        id: '',
        cherwellTicket: 0,
        type: '',
        certPort: 0,
        selfSigned: false
    }
};

export const mapStateToProps = (
    state: State,
    {reportName}: CommentModalContainerProps
): CommentModalDataProps => {
    return {
        visible:
            state.cia.certificateInteraction.visibleOnReport.get(reportName) ||
            false,
        rowData: state.cia.certificateInteraction.activeRowPerReport.get(
            reportName
        ),
        certificateTypes: state.cia.claimsReducer.claims.certificate
    };
};

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    {
        reportName,
        editCertificate,
        deleteCertificate,
        client
    }: CommentModalContainerProps & {
        editCertificate: MutationFunc<{}, EditCertificateVariables>;
        deleteCertificate: MutationFunc<{}, DeleteCertificateVariables>;
        client: ApolloClient;
    }
): CommentModalActionProps => ({
    onCancel: () => {
        dispatch(setDetailsModalVisibility(reportName, DefaultRowData, false));
    },
    onSubmit: () => {
        dispatch(setDetailsModalVisibility(reportName, DefaultRowData, false));
    },
    onEdit: async (data: EditCertificateVariables) => {
        try {
            await editCertificate({
                variables: {
                    ...data
                }
            });
            dispatch(
                setDetailsModalVisibility(reportName, DefaultRowData, false)
            );
            client.resetStore();
            message.success('Successfully edited the certificate!');
            return true;
        } catch (error) {
            if (error.graphQLErrors) {
                const apolloError = error as ApolloError;
                for (const err of apolloError.graphQLErrors) {
                    message.error(err.message);
                }
            }
            return false;
        }
    },
    onDelete: async (rowData: RowData) => {
        try {
            await deleteCertificate({
                variables: {
                    id: rowData.header.id
                }
            });
            dispatch(
                setDetailsModalVisibility(reportName, DefaultRowData, false)
            );
            client.resetStore();
            message.success('Successfully deleted the certificate!');
            return true;
        } catch (error) {
            if (error.graphQLErrors) {
                const apolloError = error as ApolloError;
                for (const err of apolloError.graphQLErrors) {
                    message.error(err.message);
                }
            }
            return false;
        }
    }
});

export const mapProps = (props: OptionProps<{}, QueryDataProps>) => {
    if (props.data?.error) {
        return {
            contacts: [{name: ''}]
        };
    }

    if (props.data !== undefined) {
        return {
            contacts: props.data.loading
                ? [{name: ''}]
                : props.data.views.contacts.contactOverview.elements
        };
    } else {
        return {
            contacts: [{name: ''}]
        };
    }
};

export const DetailsModalContainer: React.ComponentClass<
    CommentModalContainerProps
> = compose(
    withApollo,
    graphql(editCertificateMutation, {name: 'editCertificate'}),
    graphql(deleteCertificateMutation, {name: 'deleteCertificate'}),
    graphql(ContactTableViewQuery, {
        props: mapProps,
        options: {
            variables: {
                first: 10000
            }
        }
    }),
    connect(mapStateToProps, mapDispatchToProps)
)(DetailsModal);
