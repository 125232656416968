import * as React from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { AddContactInteractionAction } from '../addContactInteraction/AddContactReducer';
import { ShowDetailsModalPayload } from './CertificateIntReducer';

export interface AddProps {
    onShowDetails: () => AddContactInteractionAction<ShowDetailsModalPayload>;
}

export const DetailsDisplay: React.SFC<AddProps> = ({ onShowDetails }) => (
    <div id="DetailDisplayContainer" onClick={onShowDetails}>
        <Button type="ghost" icon={<DoubleRightOutlined />} shape="circle" />
    </div>
);
