import { gql } from 'react-apollo';

export const CertificateTableViewQuery = gql`
    query($skip: Int, $first: Int, $filters: CertificateViewModelType) {
        views {
            certificate {
                certificateOverview(
                    skip: $skip
                    first: $first
                    filters: $filters
                ) {
                    amount
                    elements {
                        header {
                            id
                            domainName
                            type
                            status
                            cherwellTicket
                            allowedToExpire
                            expirationDate
                            certPort
                            selfSigned
                        }
                        certificateDetails {
                            serialNo: serialNumber
                            issuedDate
                            lastChangeDate
                            lastCompleteUpdateRun: lastUpdateRunDate
                            lastUpdateAttempt
                            comment
                            cherwellHistory: oldCherwellTicket
                            salesLines
                            editor
                        }
                        contactData: contactInformation {
                            _id
                            userName: username
                            email
                            name
                            phone: phoneNumber
                        }
                    }
                }
            }
        }
    }
`;
