import { Action } from 'redux';
import { Map } from 'immutable';
import { RowData } from './DetailsModal';

export interface CertificateInteractionAction<T> extends Action {
    payload: T;
}

export interface ShowDetailsModalPayload {
    reportName: string;
    visible: boolean;
    data: RowData;
}

export const setDetailsModalVisibility = (
    reportName: string,
    data: RowData,
    visible: boolean
): CertificateInteractionAction<ShowDetailsModalPayload> => ({
    type: CertificateInteractionActions.SetDetailsModalVisibilityOnPage,
    payload: {
        visible,
        reportName,
        data
    }
});

export const defaultState: CertificateActionState = {
    activeRowPerReport: Map(),
    visibleOnReport: Map()
};

export interface CertificateActionState {
    activeRowPerReport: Map<string, RowData>;
    visibleOnReport: Map<string, boolean>;
}

export enum CertificateInteractionActions {
    SetDetailsModalVisibilityOnPage = 'DetailsInteraction: setDetailsModalVisibilityOnPage'
}

export const CertificateIntReducer = (
    state: CertificateActionState = defaultState,
    action: CertificateInteractionAction<ShowDetailsModalPayload>
) => {
    switch (action.type) {
        case CertificateInteractionActions.SetDetailsModalVisibilityOnPage:
            const payload = action.payload as ShowDetailsModalPayload;
            return {
                ...state,
                activeRowPerReport: state.activeRowPerReport.set(
                    action.payload.reportName,
                    {
                        ...action.payload.data
                    }
                ),
                visibleOnReport: state.visibleOnReport.set(
                    payload.reportName,
                    payload.visible
                )
            };
        default:
            return state;
    }
};
