import { combineReducers, Reducer } from 'redux';
import { GraphQLMenuReducer, GraphQLMenuState } from '../component/graphQLMenu';
import {
    CertificateActionState,
    CertificateIntReducer
} from '../component/certificateInteraction/CertificateIntReducer';
import {
    AddContactActionState,
    AddContactReducer
} from '../component/addContactInteraction/AddContactReducer';
import {
    AddCertificateActionState,
    AddCertificateReducer
} from '../component/addCertificateInteraction/AddCertificateReducer';
import {
    SetClaimsActionState,
    SetClaimsReducer
} from '../component/claims/ClaimsReducer';
import {
    ColumnToggleActionState,
    SetColumnToggleReducer
} from '../component/columnToggle/ColumnToggleReducer';

export interface CiaState {
    menuReducer: GraphQLMenuState;
    certificateInteraction: CertificateActionState;
    addContactInteraction: AddContactActionState;
    addCertificateInteraction: AddCertificateActionState;
    claimsReducer: SetClaimsActionState;
    columnToggleReducer: ColumnToggleActionState;
}

export interface ReduxState {
    cia: CiaState;
}

export const CiaReducer: Reducer<CiaState> = combineReducers({
    menuReducer: GraphQLMenuReducer,
    certificateInteraction: CertificateIntReducer,
    addContactInteraction: AddContactReducer,
    addCertificateInteraction: AddCertificateReducer,
    claimsReducer: SetClaimsReducer,
    columnToggleReducer: SetColumnToggleReducer
});

export { ReduxState as State };
