import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DetailsDisplay } from './DetailsDisplay';
import { setDetailsModalVisibility } from './CertificateIntReducer';
import { AddProps } from './DetailsDisplay';
import { RowData } from './DetailsModal';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    { reportName, data }: { reportName: string; data: RowData }
): AddProps => ({
    onShowDetails: () =>
        dispatch(setDetailsModalVisibility(reportName, data, true))
});

export const DetailsDisplayContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailsDisplay);
