import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Select, InputNumber, Checkbox } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
const FormItem = Form.Item;
const Option = Select.Option;

const SalesLines = [
    'OTHER',
    'MM_DE',
    'SE_DE',
    'MM_AT',
    'SE_AT',
    'MM_NL',
    'SE_NL',
    'MM_BE',
    'SE_BE',
    'MM_TR',
    'SE_TR',
    'MM_PL',
    'MM_CH',
    'MM_ES',
    'MM_PT',
    'MM_RU',
    'MM_SE',
    'MM_HU',
    'MM_GR',
    'SE_LU'
];

interface UserFormProps extends FormComponentProps {
    visible: boolean;
    onSubmit: () => void;
    onCancel: () => void;
    certificateTypes: Array<string>;
    contacts: Array<ContactProps>;
}

interface ContactProps {
    id: string;
    email: string;
    name: string;
    username: string;
    phoneNumber: string;
}

// tslint:disable-next-line
export const FilterContacts = (input: string, option: any) => {
    const firstText = option.props.children[0].props.children;
    const secondText = option.props.children[1].props.children;
    const completeText = firstText + ' / ' + secondText;

    return completeText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

class CertificateForm extends React.Component<UserFormProps> {
    constructor(props: UserFormProps) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };

        const {
            visible,
            onSubmit,
            onCancel,
            certificateTypes,
            contacts
        } = this.props;
        return (
            <Modal
                visible={visible}
                onOk={onSubmit}
                onCancel={onCancel}
                title="Create a new certificate"
                okText="Create"
                width="700px"
            >
                <Form>
                    <FormItem {...formItemLayout} label="Domain">
                        {getFieldDecorator('domainName', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input the Domain Name!'
                                }
                            ]
                        })(<Input placeholder={'Input the domain name'}/>)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Contacts">
                        {getFieldDecorator('contacts', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input the Contacts!'
                                }
                            ]
                        })(
                            <Select
                                showSearch={true}
                                mode="multiple"
                                placeholder="Select the contacts"
                                filterOption={(input, option) =>
                                    FilterContacts(input, option)
                                }
                            >
                                {contacts.map(type => {
                                    return (
                                        <Option value={type.id} key={type.name}>
                                            <div>
                                                {type.name +
                                                    ' / ' +
                                                    type.username}
                                            </div>
                                            <div>
                                                {type.email +
                                                    ' / ' +
                                                    type.phoneNumber}
                                            </div>
                                        </Option>
                                    );
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Type">
                        {getFieldDecorator('type', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input the Type!'
                                }
                            ]
                        })(
                            <Select placeholder="Select a type">
                                {certificateTypes.map((type: string) => {
                                    return (
                                        <Option key={type} value={type}>
                                            {type}
                                        </Option>
                                    );
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Sales Lines">
                        {getFieldDecorator('salesLines', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input the Sales Lines!'
                                }
                            ]
                        })(
                            <Select
                                mode="multiple"
                                placeholder="Select the sales lines"
                            >
                                {SalesLines.map((lines: string) => {
                                    return (
                                        <Option key={lines} value={lines}>
                                            {lines}
                                        </Option>
                                    );
                                })}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Port">
                        {getFieldDecorator('port', {
                            rules: [
                                {
                                    required: false
                                }
                            ]
                        })(<InputNumber
                                min={0}
                                max={65535}
                                style={{width: '100%'}}
                                placeholder={'Input if custom port'}
                            />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="Self Signed">
                        {getFieldDecorator('selfSigned', {
                            rules: [
                                {
                                    required: false
                                }
                            ]
                        })(<Checkbox/>)}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}
// tslint:disable-next-line
export const AddCertificateForm: any = Form.create()(CertificateForm);
