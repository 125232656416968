import { graphql, gql, compose } from 'react-apollo';
import { ContactTableView } from './ContactTableView';
import { mapUrlToOptions } from '../../../util/UrlUtil';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { QueryProps } from 'react-apollo/types';
import { FilterURLType, State } from '../../../store/index';
import { connect } from 'react-redux';

export const ContactTableViewQuery = gql`
    query ContactTableViewQuery(
        $skip: Int
        $first: Int
        $filters: ContactViewModelType
    ) {
        views {
            contacts {
                contactOverview(skip: $skip, first: $first, filters: $filters) {
                    elements {
                        id
                        name
                        username
                        email
                        phoneNumber
                    }
                    amount
                }
            }
        }
    }
`;

export interface MapPropCompleteDefinition {
    data: QueryProps & MapPropDefinition;
    ownProps: RouteComponentProps<FilterURLType>;
}

export interface MapPropDefinition {
    views: {
        contacts: {
            contactOverview: {
                elements: Array<{}>;
                amount: number;
            };
        };
    };
}

export const mapProps = (props: MapPropCompleteDefinition) => {
    if (props.data.error) {
        return {
            error: props.data.error
        };
    }

    return {
        numberOfRows: props.data.loading
            ? 1
            : props.data.views.contacts.contactOverview.amount,
        rowData: props.data.loading
            ? []
            : props.data.views.contacts.contactOverview.elements,
        loading: props.data.loading
    };
};

export const mapOptions = (props: RouteComponentProps<FilterURLType>) => {
    return mapUrlToOptions(props.match.params.filter);
};

export const mapStateToProps = (state: State): { visibleColumns: string[] } => {
    return {
        visibleColumns: state.cia.columnToggleReducer.columnsVisible.get(
            'CONTACT'
        )
    };
};

export const ContactTableViewContainer = compose(
    withRouter,
    graphql(ContactTableViewQuery, {
        props: mapProps,
        options: mapOptions
    }),
    connect(mapStateToProps)
)(ContactTableView);
