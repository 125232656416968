import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal } from 'antd';
const FormItem = Form.Item;
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { ButtonType } from 'antd/lib/button';

interface UserFormProps extends FormComponentProps {
    visible: boolean;
    onSubmit: () => void;
    onCancel: () => void;
    data: {
        name: string;
        username: string;
        email: string;
        phoneNumber: string;
    };
    functionality: string;
}

class ContactForm extends React.Component<UserFormProps> {
    constructor(props: UserFormProps) {
        super(props);
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };

        const { visible, onSubmit, onCancel, data, functionality } = this.props;

        let modalTitle: string = '';
        let buttonTitle: string = '';
        let buttonType: ButtonType = 'primary';
        let InputDisabled: boolean = false;
        switch (functionality) {
            case 'EDIT':
                modalTitle = 'Edit this contact';
                buttonTitle = 'Edit';
                break;
            case 'ADD':
                modalTitle = 'Create a new contact';
                buttonTitle = 'Create';
                break;
            case 'REMOVE':
                modalTitle = 'Remove this contact';
                buttonTitle = 'Remove';
                buttonType = 'primary';
                InputDisabled = true;
                break;
            default:
                buttonTitle = 'Ok';
                break;
        }

        return (
            <Modal
                visible={visible}
                onOk={onSubmit}
                onCancel={onCancel}
                title={modalTitle}
                okText={buttonTitle}
                okType={buttonType}
                width="700px"
            >
                <Form>
                    <FormItem {...formItemLayout} label="Name">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your Name!'
                                }
                            ],
                            initialValue: data.name
                        })(
                            <Input
                                disabled={InputDisabled}
                                className="nameInputForm"
                            />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="UserName or GroupName">
                        {getFieldDecorator('username', {
                            rules: [
                                {
                                    required: true,
                                    message:
                                        'Please input your UserName or GroupName!'
                                }
                            ],
                            initialValue: data.username
                        })(
                            <Input
                                disabled={InputDisabled}
                                className="usernameInputForm"
                            />
                        )}
                    </FormItem>
                    <FormItem {...formItemLayout} label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input your E-mail!'
                                }
                            ],
                            initialValue: data.email
                        })(<Input disabled={InputDisabled} />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label="PhoneNumber">
                        {getFieldDecorator('phoneNumber', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input your PhoneNumber!'
                                }
                            ],
                            initialValue: data.phoneNumber
                        })(<Input disabled={InputDisabled} />)}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}
// tslint:disable-next-line
export const AddContactForm: any = Form.create()(ContactForm);
