/**
 * Created by geroe on 16.03.2016.
 */
/* tslint:disable:no-any */
import React from 'react';
import { FilterControl } from './FilterControl';
import { CheckOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { StoreConnector } from './StoreConnector';

import { FilterDataType } from './filterService/FilterSchema';

const { Group } = Button;

var Reflux = require('reflux');
var $ = require('jquery');

/*global _, $, Reflux*/

/**
 * Panel with the ability to represent multiple filters on data.
 * Receives a list of FilterFields and provides FilterControls.
 * In order to receive the list of user-defined filters specify
 * an onChange method that takes on argument which will be the
 * list of filters.
 */
export class FilterPanel extends React.Component<any> {
    _filterStore: any;
    extend: any;
    _unsubscribeChangeStore: any;

    static get defaultProps() {
        return {
            onApply: (/*filters*/) => {
                /**/
            },
            onChange: (/*filters*/) => {
                /**/
            },
            filters: []
        };
    }

    constructor(props: any) {
        super(props);
        this.state = {};
        const self = this as any;

        this._getFiltersCopy = this._getFiltersCopy.bind(this);
        this._renderFilterRow = this._renderFilterRow.bind(this);
        this._addFilter = this._addFilter.bind(this);
        this._clearFilters = this._clearFilters.bind(this);
        this._applyFilters = this._applyFilters.bind(this);
        this._filterStore = Reflux.createStore({
            handleFilterChange(index: any, prop: any, value: any) {
                const newFilters = self._getFiltersCopy();

                switch (prop) {
                    case 'field':
                        newFilters[index][prop] = value;
                        const newDefinition = self.props.schema.getDefinition(
                            value
                        );
                        const oldOperator = newFilters[index].operator;
                        const newOperator = newDefinition.operators[0];

                        if (!newDefinition.getOperator(oldOperator)) {
                            newFilters[index].operator = newOperator.name;
                            newFilters[index].value = newOperator.type.isList
                                ? []
                                : newOperator.type.defaultValue();
                        }
                        break;
                    case 'operator':
                        const isArray =
                            newFilters[index].value instanceof Array;
                        const wantsArray = self._getTypeOfOperator(
                            newFilters[index].field,
                            value
                        ).isList;

                        if (wantsArray && !isArray) {
                            newFilters[index].value = [];
                        }
                        if (!wantsArray && isArray) {
                            newFilters[index].value = '';
                        }
                        newFilters[index][
                            prop
                        ] = self.props.schema
                            .getDefinition(newFilters[index].field)
                            .getOperator(value).name;
                        break;
                    case 'value':
                        newFilters[index][prop] = self._convertValue(
                            value,
                            self._getTypeOfOperator(
                                newFilters[index].field,
                                newFilters[index].operator
                            )
                        );
                        break;
                    default:
                        newFilters[index][prop] = value;
                        break;
                }
                this.trigger(newFilters);
            },
            handleFilterRemove(index: any) {
                const newFilters = self._getFiltersCopy();

                newFilters.splice(index, 1);
                this.trigger(newFilters);
            }
        });
    }

    _convertValue(value: any, targetType: any) {
        if (targetType.isList) {
            if (value instanceof Array) {
                return value.map(v => this._convertSingleValue(v, targetType));
            } else {
                return [this._convertSingleValue(value, targetType)];
            }
        } else {
            return this._convertSingleValue(value, targetType);
        }
    }

    _convertSingleValue(value: any, targetType: any) {
        switch (targetType.dataType) {
            case FilterDataType.DATETIME:
                return (value instanceof Date && value.toISOString()) || value;
            case FilterDataType.BOOL:
                return value === 'true' || value === true;
            case FilterDataType.INT:
                return parseInt(value, undefined);
            case FilterDataType.FLOAT:
                return parseFloat(value);
            default:
                return value;
        }
    }

    _getFiltersCopy() {
        return $.extend(true, [], (this.props as any).filters);
    }

    _getTypeOfOperator(field: any, operator: any) {
        return (this.props as any).schema
            .getDefinition(field)
            .getOperator(operator).type;
    }

    _getValidFilters() {
        return (this.props as any).filters.filter(
            (filter: any) =>
                (this.props as any).schema.validateFilter(filter).isValid
        );
    }

    componentDidMount() {
        this._unsubscribeChangeStore = this._filterStore.listen(
            (this.props as any).onChange
        );
    }

    componentWillUnmount() {
        this._unsubscribeChangeStore();
    }

    _renderFilterRow(filter: any, i: any, tree: any) {
        if ((this.props as any).schema) {
            const attr = {
                schema: (this.props as any).schema,
                tree,
                field: filter.field,
                isTableRow: true,
                key: i,
                operator: (this.props as any).schema
                    .getDefinition(filter.field)
                    .getOperator(filter.operator),
                removeStoreConnector: new StoreConnector(
                    this._filterStore,
                    this._filterStore.handleFilterRemove,
                    [i]
                ),
                changeStoreConnector: new StoreConnector(
                    this._filterStore,
                    this._filterStore.handleFilterChange,
                    [i]
                ),
                value: filter.value || null
            };

            return <FilterControl {...attr} />;
        } else {
            return null;
        }
    }

    _addFilter() {
        const newFilters = this._getFiltersCopy();

        newFilters.push((this.props as any).schema.getDefaultFilter());
        (this.props as any).onChange(newFilters);
    }

    _applyFilters() {
        (this.props as any).onApply((this.props as any).filters);
    }

    _clearFilters() {
        (this.props as any).onApply([]);
    }

    _buildTreeFromDefinition(definition: any, parent: any = '') {
        const value = [parent, definition.name].filter(s => !!s).join('.');
        const result: any = {
            label: definition.name,
            key: value,
            value
        };

        if (definition.isComplex()) {
            result.disabled = true;
            result.children = definition.subFields.map((field: any) =>
                this._buildTreeFromDefinition(field, value)
            );
        }

        return result;
    }

    render() {
        if ((this.props as any).schema) {
            const tree = (this.props as any).schema.definitions.map((d: any) =>
                this._buildTreeFromDefinition(d)
            );
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}
                >
                    {(this.props as any).filters.map((f: any, i: any) =>
                        this._renderFilterRow(f, i, tree)
                    )}
                    <Group style={{ alignSelf: 'flex-end' }}>
                        <Button onClick={this._addFilter}>
                            <PlusOutlined /> Add
                        </Button>
                        <Button type="default" danger onClick={this._clearFilters}>
                            <DeleteOutlined /> Clear
                        </Button>
                        <Button
                            style={{ color: 'green' }}
                            onClick={this._applyFilters}
                        >
                            <CheckOutlined /> Apply
                        </Button>
                    </Group>
                </div>
            );
        } else {
            return null;
        }
    }
}
