import * as React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
var moment = require('moment');
import { DetailsDisplayContainer } from '../../../component/certificateInteraction/DetailsDisplayContainer';
import { RowData } from '../../../component/certificateInteraction/DetailsModal';

export const renderDate = (text: string | null) =>
    text === null || text === undefined ? (
        <p />
    ) : (
        <p>{moment(text).format()}</p>
    );

export const renderBoolean = (text: string | null) => {
    if (text) {
        return <CheckCircleOutlined />;
    } else {
        return <p>-</p>;
    }
};

export const renderDaysUntil = (text: string | null) => {
    if (text !== null) {
        return moment(text).fromNow();
    } else {
        return '';
    }
};

export const renderCherwellUrl = (cherwellCaseNumber: string | null) => {
    const cherwellApplicationURL =
        'XXXplaceholder_for_CherwellApplicationURLXXX';
    if (!cherwellCaseNumber || parseInt(cherwellCaseNumber, 10) === 0) {
        return <p>-</p>;
    } else if (
        cherwellApplicationURL.indexOf('XXXplaceholder_') >= 0 ||
        !cherwellApplicationURL ||
        process.env.npm_lifecycle_event === 'test' ||
        process.env.npm_lifecycle_event === 'coverage'
    ) {
        return <p>{cherwellCaseNumber}</p>;
    } else {
        const chewellTicketUrl: string =
            cherwellApplicationURL + 'incident/' + cherwellCaseNumber;
        return (
            <a href={chewellTicketUrl} target="_blank">
                {cherwellCaseNumber}
            </a>
        );
    }
};

export const renderDetails = (text: string, record: RowData, index: number) => (
    <DetailsDisplayContainer reportName={text} data={record} />
);

export const Columns = (reportName: string, visibleColumns: string[]) => {
    const completeColumns = [
        {
            title: 'Domain Name',
            dataIndex: 'domainName',
            key: 'domainName'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Current Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Cherwell',
            dataIndex: 'cherwellTicket',
            key: 'cherwellTicket',
            render: renderCherwellUrl
        },
        {
            title: 'Allowed To Expire',
            dataIndex: 'allowedToExpire',
            key: 'allowedToExpire',
            render: renderBoolean
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: renderDate
        },
        {
            title: 'Days Until Expiration',
            dataIndex: 'expirationDate',
            key: 'daysUntilExpiration',
            render: renderDaysUntil
        },
        {
            title: 'Port',
            dataIndex: 'port',
            key: 'port',
            render: (text: number) => (text === 443 ? null : text)
        },
        {
            title: 'Details',
            key: 'details',
            render: (text: string, record: RowData, index: number) =>
                renderDetails(reportName, record, index)
        }
    ];

    var selectedColumns = completeColumns;

    if (visibleColumns !== undefined) {
        selectedColumns = completeColumns.filter(column => {
            return visibleColumns.indexOf(column.key) === -1;
        });
    }

    return selectedColumns;
};
