import { graphql, compose } from 'react-apollo';
import { CertificateTableView } from './CertificateTableView';
import { mapUrlToOptions } from '../../../util/UrlUtil';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { QueryProps } from 'react-apollo/types';
import { FilterURLType, State } from '../../../store/index';
import { CertificateTableViewQuery } from './CertificateTableViewQuery';
import { connect } from 'react-redux';

export interface MapPropCompleteDefinition {
    data: QueryProps & MapPropDefinition;
    ownProps: RouteComponentProps<FilterURLType>;
}

export interface MapPropDefinition {
    views: {
        certificate: {
            certificateOverview: {
                elements: Array<{}>;
                amount: number;
            };
        };
    };
}

export const mapProps = (props: MapPropCompleteDefinition) => {
    if (props.data.error) {
        return {
            error: props.data.error
        };
    }

    return {
        numberOfRows: props.data.loading
            ? 1
            : props.data.views.certificate.certificateOverview.amount,
        rowData: props.data.loading
            ? []
            : props.data.views.certificate.certificateOverview.elements,
        loading: props.data.loading
    };
};

export const mapOptions = (props: RouteComponentProps<FilterURLType>) => {
    return mapUrlToOptions(props.match.params.filter);
};

export const mapStateToProps = (state: State): { visibleColumns: string[] } => {
    return {
        visibleColumns: state.cia.columnToggleReducer.columnsVisible.get(
            'CERTIFICATE'
        )
    };
};

export const CertificateTableViewContainer = compose(
    withRouter,
    graphql(CertificateTableViewQuery, {
        props: mapProps,
        options: mapOptions
    }),
    connect(mapStateToProps)
)(CertificateTableView);
