/**
 * Created by geroe on 19.04.2016.
 */
// import { mixin, TLoggable } from 'fifa-javascript-utilities/GeneralUtils';
/* tslint:disable:no-any */
import { ValidationMessage } from './ValidationMessage';
import {
    assert
    // isArrayOf
} from './utils';
import { isArray, isNaN, isString, values } from 'lodash';

export const FilterDataType = {
    STRING: 'STRING',
    INT: 'INT',
    FLOAT: 'FLOAT',
    BOOL: 'BOOL',
    DATETIME: 'DATETIME',
    COMPLEX: 'COMPLEX'
};
export const ReadableFilterDataType = {
    STRING: 'a text',
    INT: 'a whole number',
    FLOAT: 'a number',
    BOOL: 'true or false',
    DATETIME: 'a time value',
    COMPLEX: 'a complex value'
};
const types = values(FilterDataType);

export class FilterType {
    _dataType: any;
    _isList: any;

    static isValidType(name: any) {
        return types.indexOf(name) !== -1;
    }

    constructor({ dataType, isList = false }: any) {
        assert(
            FilterType.isValidType(dataType),
            `Invalid data type '${dataType}'`
        );
        assert(typeof isList === 'boolean', 'isList must be boolean');
        this._dataType = dataType;
        this._isList = isList;
    }

    get dataType() {
        return this._dataType;
    }

    get isList() {
        return this._isList;
    }

    serialize() {
        return {
            dataType: this._dataType,
            isList: this._isList
        };
    }

    _validateSingleValue(value: any) {
        const result = {
            errorMessage: '',
            value
        } as any;
        const setError = () => {
            result.errorMessage = `value "${value}" is not ${
                ReadableFilterDataType[this._dataType]
            }`;
        };

        switch (this._dataType) {
            case FilterDataType.STRING:
                result.isValid = isString(value);
                if (!result.isValid) {
                    setError();
                }
                break;
            case FilterDataType.INT:
                result.isValid = Number.isInteger(value);
                if (!result.isValid) {
                    setError();
                }
                break;
            case FilterDataType.FLOAT:
                result.isValid = typeof value === 'number';
                if (!result.isValid) {
                    setError();
                }
                break;
            case FilterDataType.BOOL:
                result.isValid = typeof value === 'boolean';
                if (!result.isValid) {
                    setError();
                }
                break;
            case FilterDataType.DATETIME:
                result.isValid =
                    isString(value) && !isNaN(new Date(value).valueOf());
                if (!result.isValid) {
                    setError();
                }
                break;
            case FilterDataType.COMPLEX:
                result.isValid = false;
                result.errorMessage =
                    'cant validate complex values. break it down...';
                break;
            default:
                result.isValid = false;
                result.errorMessage = `unknown data type:${
                    this._dataType
                }... something is corrupt!`;
        }

        return new ValidationMessage(result);
    }

    defaultValue() {
        if (this.isList) {
            return [];
        }
        let result;

        switch (this._dataType) {
            case FilterDataType.STRING:
                result = '';
                break;
            case FilterDataType.INT:
                result = 0;
                break;
            case FilterDataType.FLOAT:
                result = 0.0;
                break;
            case FilterDataType.BOOL:
                result = false;
                break;
            case FilterDataType.DATETIME:
                result = new Date().toISOString();
                break;
            case FilterDataType.COMPLEX:
                result = {};
                break;
            default:
                throw new Error(
                    `unknown data type:${
                        this._dataType
                    }... something is corrupt!`
                );
        }

        return result;
    }

    validateValue(value: any) {
        if (this._isList) {
            if (!isArray(value)) {
                return new ValidationMessage({
                    isValid: false,
                    errorMessage: 'value is not an array',
                    value
                });
            }
            return ValidationMessage.reduceMessages(
                value.map(v => this._validateSingleValue(v)),
                value
            );
        } else {
            return this._validateSingleValue(value);
        }
    }
}
