import * as React from 'react';
import { v4 as createUUID } from 'uuid';
import {
    AFDSchema,
    TreeData,
    FilterType,
    FilterRowRender,
    FilterOperator,
    FilterWithPagDefinition,
    PCR,
    GQLFilterTypeDefinition
} from './Filter';
import { FilterPanel } from './FilterPanel';
import { getFilterSelectTree, gqlGraphToAFD } from './FilterUtils';
import { FilterMapper } from './FilterMapper';
import { convertBase64ToObjectWithFallback } from '../../util/UrlUtil';

export interface FilterPanelContainerDataProps {
    filter: string;
    parentChildRelations: Array<PCR>;
    definitions: Array<GQLFilterTypeDefinition>;
    filterMapper?: FilterMapper;
}

export interface FilterPanelContainerUrlProps {
    parentChildRelations: Array<PCR>;
    definitions: Array<GQLFilterTypeDefinition>;
    filterMapper?: FilterMapper;
}

export interface FilterPanelContainerActionProps {
    onFilterApplyUrl: (Filter: FilterWithPagDefinition) => void;
}

export interface FilterPanelContainerProps
    extends FilterPanelContainerDataProps,
        FilterPanelContainerActionProps {}

export interface FilterPanelContainerState {
    filters: Array<FilterRowRender>;
    filterTree: Array<TreeData>;
    filterSchema: AFDSchema;
    filterTypes: Array<FilterType>;
}

export interface FilterRowContainerProps {
    getFilterType: (filterTypeId: string) => FilterType;
    filterTree: Array<TreeData>;
    onRemove: () => void;
    filterMapper?: FilterMapper;
}

export const defaultFilterOperator = {
    id: '',
    targetType: {
        id: 'none'
    },
    isMulti: false
};

export const defaultPaginationOptions = {
    rowsPerPage: 10,
    activePage: 1
};

export const defaultPagAndFilterArray = {
    filters: [],
    paginationOptions: {
        rowsPerPage: 10,
        activePage: 1
    }
};

export class FilterPanelContainer extends React.Component<
    FilterPanelContainerProps,
    FilterPanelContainerState
> {
    constructor(a: FilterPanelContainerProps, b?: {}) {
        super(a, b);
        const afdSchema: AFDSchema = gqlGraphToAFD(
            this.props.parentChildRelations,
            this.props.definitions
        );
        this.state = {
            filters: [],
            filterTree: getFilterSelectTree(afdSchema),
            filterTypes: this.props.definitions.map(f => f.type),
            filterSchema: { filters: [] }
        };
        this.getFilterType = this.getFilterType.bind(this);
        this.onClearFilters = this.onClearFilters.bind(this);
        this.onAddFilter = this.onAddFilter.bind(this);
        this.onRemoveFilter = this.onRemoveFilter.bind(this);
        this.onApplyFilter = this.onApplyFilter.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onOperatorChange = this.onOperatorChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDeselectChange = this.onDeselectChange.bind(this);
    }

    componentWillReceiveProps(props: FilterPanelContainerProps) {
        const newAfd = gqlGraphToAFD(
            props.parentChildRelations,
            props.definitions
        );
        this.setState({
            filters: convertBase64ToObjectWithFallback(
                props.filter,
                defaultPagAndFilterArray
            ).filters,
            filterTree: getFilterSelectTree(newAfd),
            filterSchema: newAfd,
            filterTypes: props.definitions.map(f => f.type)
        });
    }

    getFilterType(filterId: string): FilterType {
        if (filterId === '' || !filterId) {
            return {
                id: '',
                operators: []
            };
        } else {
            return this.state.filterTypes.filter(
                filter => filter.id === filterId
            )[0];
        }
    }

    onClearFilters() {
        this.setState({
            filters: [
                {
                    uuid: createUUID(),
                    filterType: {
                        id: '',
                        operators: []
                    },
                    filterOperator: defaultFilterOperator,
                    targetTypes: []
                }
            ]
        });
    }

    onAddFilter() {
        this.setState({
            filters: [
                ...this.state.filters,
                {
                    uuid: createUUID(),
                    filterType: {
                        id: '',
                        operators: []
                    },
                    filterOperator: defaultFilterOperator,
                    targetTypes: []
                }
            ]
        });
    }

    onRemoveFilter(filter: FilterRowRender) {
        if (this.state.filters.length === 1) {
            this.setState({
                filters: [
                    {
                        uuid: createUUID(),
                        filterType: {
                            id: '',
                            operators: []
                        },
                        filterOperator: defaultFilterOperator,
                        targetTypes: []
                    }
                ]
            });
        } else {
            this.setState({
                filters: this.state.filters.filter(f => f.uuid !== filter.uuid)
            });
        }
    }

    onApplyFilter() {
        this.props.onFilterApplyUrl(
            Object.assign(
                {},
                {
                    filters: this.state.filters,
                    paginationOptions: defaultPaginationOptions
                }
            )
        );
    }

    onFilterChange(filter: FilterRowRender, filterId: string) {
        this.setState({
            filters: this.state.filters.map(row => {
                if (row.uuid === filter.uuid) {
                    row.filterType = this.getFilterType(filterId);
                    row.filterOperator = Object.assign(
                        {},
                        defaultFilterOperator
                    );
                    row.targetTypes = [];
                }
                return row;
            })
        });
    }

    onOperatorChange(filter: FilterRowRender, operatorId: string) {
        this.setState({
            filters: this.state.filters.map(row => {
                if (row.uuid === filter.uuid) {
                    row.filterOperator = row.filterType.operators.filter(
                        (operator: FilterOperator) => operator.id === operatorId
                    )[0];
                    row.targetTypes = [];
                }
                return row;
            })
        });
    }

    onSelectChange(filter: FilterRowRender, newValue: string) {
        this.setState({
            filters: this.state.filters.map(row => {
                if (row.uuid === filter.uuid) {
                    if (row.filterOperator.isMulti) {
                        row.targetTypes = row.targetTypes.concat(newValue);
                    } else {
                        row.targetTypes = [newValue];
                    }
                }
                return row;
            })
        });
    }

    onDeselectChange(filter: FilterRowRender, removeValue: string) {
        this.setState({
            filters: this.state.filters.map(row => {
                if (row.uuid === filter.uuid) {
                    row.targetTypes = row.targetTypes.filter(
                        (type: string) => type !== removeValue
                    );
                }
                return row;
            })
        });
    }

    public render() {
        return (
            <FilterPanel
                filters={this.state.filters}
                filterTree={this.state.filterTree}
                onAddFilter={this.onAddFilter}
                onClearFilters={this.onClearFilters}
                onRemoveFilter={this.onRemoveFilter}
                filterMapper={this.props.filterMapper}
                onApplyFilter={this.onApplyFilter}
                onFilterChange={this.onFilterChange}
                onOperatorChange={this.onOperatorChange}
                onSelectChange={this.onSelectChange}
                onDeselectChange={this.onDeselectChange}
            />
        );
    }
}
