import * as React from 'react';
import { Modal, Button, message } from 'antd';
import { DetailsModalInfo } from './DetailsModalInfo';

export interface RowData {
    domainName: string;
    type: string;
    status: string;
    cherwellTicket: number;
    allowedToExpire: boolean;
    expirationDate: string;
    port: number;
    certificateDetails: {
        cherwellHistory: number;
        comment: string;
        editor: string;
        issuedDate: string;
        lastChangeDate: string;
        lastCompleteUpdateRun: string;
        lastUpdateAttempt: string;
        salesLines: [string];
        serialNo: string;
    };
    contactData: [ContactDataProps];
    header: {
        allowedToExpire: boolean;
        status: string;
        domainName: string;
        expirationDate: string;
        id: string;
        cherwellTicket: number;
        type: string;
        certPort: number;
        selfSigned: boolean;
    };
}

export interface ContactDataProps {
    _id: string;
    id?: string;
    email: string;
    name: string;
    phoneNumber: string;
    username: string;
}

export interface CommentModalDataProps {
    visible: boolean;
    rowData: RowData;
    certificateTypes: {
        add: string[];
        edit: string[];
        remove: string[];
    };
}

interface CommentModalDataPropsWithContacts extends CommentModalDataProps {
    contacts: [ContactDataProps];
}

export interface CommentModalActionProps {
    onSubmit: (rowData: RowData) => void;
    onEdit: (rowData: {}) => Promise<boolean>;
    onDelete: (rowData: RowData) => Promise<boolean>;
    onCancel: () => void;
}

export interface CommentModalProps
    extends CommentModalDataPropsWithContacts,
    CommentModalActionProps { }

export interface CommentModalState extends InfoState {
    confirmDelete: boolean;
    editPressed: boolean;
}

interface InfoState {
    id: string | null;
    domainName: string | null;
    type: string | null;
    status: string | null;
    cherwellTicket: number | null;
    allowedToExpire: boolean | null;
    expirationDate: string | null;
    issuedDate: string | null;
    lastChangeDate: string | null;
    comment: string | null;
    lastUpdateAttempt: string | null;
    lastCompleteUpdateRun: string | null;
    editor: string | null;
    salesLines: [string] | null;
    contacts: [ContactDataProps] | null;
    port: number | null;
    selfSigned: boolean | null;
}

export class DetailsModal extends React.Component<
    CommentModalProps,
    CommentModalState
> {
    constructor(props: CommentModalProps) {
        super(props);
        this.state = {
            confirmDelete: false,
            editPressed: false,
            id: null,
            domainName: null,
            type: null,
            status: null,
            cherwellTicket: null,
            allowedToExpire: null,
            expirationDate: null,
            issuedDate: null,
            lastChangeDate: null,
            comment: null,
            lastUpdateAttempt: null,
            lastCompleteUpdateRun: null,
            editor: null,
            salesLines: null,
            contacts: null,
            port: null,
            selfSigned: null
        };
        this.confirmDeleteYes = this.confirmDeleteYes.bind(this);
        this.confirmDeleteNo = this.confirmDeleteNo.bind(this);
        this.confirmEditYes = this.confirmEditYes.bind(this);
        this.confirmEditNo = this.confirmEditNo.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }

    setDefaultState() {
        this.setState({
            confirmDelete: false,
            editPressed: false,
            id: null,
            domainName: null,
            type: null,
            status: null,
            cherwellTicket: null,
            allowedToExpire: null,
            expirationDate: null,
            issuedDate: null,
            lastChangeDate: null,
            comment: null,
            lastUpdateAttempt: null,
            lastCompleteUpdateRun: null,
            editor: null,
            salesLines: null,
            contacts: null,
            port: null,
            selfSigned: null
        });
    }

    handleConfirmDelete(confirmDelete: boolean) {
        if (
            this.props.certificateTypes.remove.indexOf(
                this.props.rowData.type
            ) >= 0
        ) {
            const { rowData, onDelete } = this.props;
            if (confirmDelete) {
                return (
                    <span key="deleteSpan">
                        <span key="deleteTitle" style={{ marginRight: '10px' }}>
                            Are you sure?
                        </span>
                        <Button
                            key="yesDelete"
                            type="default" danger
                            onClick={() => {
                                this.setState({
                                    confirmDelete: false,
                                    editPressed: false
                                });
                                onDelete(rowData);
                            }}
                        >
                            YES, DELETE
                        </Button>
                        <Button key="noDelete" onClick={this.confirmDeleteNo}>
                            NO
                        </Button>
                    </span>
                );
            } else {
                return (
                    <Button
                        key="delete"
                        type="default" danger
                        onClick={this.confirmDeleteYes}
                    >
                        Delete
                    </Button>
                );
            }
        } else {
            return <div />;
        }
    }

    handleEditPressed(editPressed: boolean) {
        const { onCancel } = this.props;

        if (
            this.props.certificateTypes.edit.indexOf(this.props.rowData.type) >=
            0
        ) {
            if (editPressed) {
                return (
                    <span key="editSpan" style={{ marginLeft: '10px' }}>
                        <Button key="back" onClick={this.confirmEditNo}>
                            Cancel Edit
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={() => {
                                this.handleEditSubmit(this.state);
                            }}
                        >
                            Submit Edit
                        </Button>
                    </span>
                );
            } else {
                return (
                    <span key="noEditSpan" style={{ marginLeft: '10px' }}>
                        <Button key="edit" onClick={this.confirmEditYes}>
                            Edit
                        </Button>
                        <Button
                            key="back"
                            type="primary"
                            onClick={() => onCancel()}
                        >
                            Return
                        </Button>
                    </span>
                );
            }
        } else {
            return (
                <Button key="back" type="primary" onClick={() => onCancel()}>
                    Return
                </Button>
            );
        }
    }

    async handleEditSubmit(rowData: {}) {
        const {
            confirmDelete,
            editPressed,
            ...filtered
        } = rowData as CommentModalState;
        if (filtered.id === null) {
            message.warning('No fields were modified!');
        } else {
            var filteredNulls = Object.keys(filtered).reduce(function (
                previous: {},
                current: string
            ) {
                if (filtered[current] !== null) {
                    previous[current] = filtered[current];
                }
                return previous;
            },
                {});

            if (await this.props.onEdit(filteredNulls)) {
                this.setDefaultState();
            }
        }
    }

    handleStateChange(obj: {}) {
        this.setState({
            id: this.props.rowData.header.id
        });
        this.setState(obj);
    }

    confirmDeleteYes() {
        this.setState({
            id: null,
            confirmDelete: true
        });
    }

    confirmDeleteNo() {
        this.setState({
            confirmDelete: false
        });
    }

    confirmEditYes() {
        this.setState({
            id: null,
            editPressed: true
        });
    }

    confirmEditNo() {
        this.setDefaultState();
    }

    render() {
        const {
            visible,
            rowData,
            onSubmit,
            onCancel,
            certificateTypes,
            contacts
        } = this.props;
        const { confirmDelete, editPressed } = this.state;
        if (rowData !== undefined) {
            return (
                <Modal
                    visible={visible}
                    onOk={() => {
                        onSubmit(rowData);
                    }}
                    onCancel={() => {
                        onCancel();
                        this.setDefaultState();
                    }}
                    width="auto"
                    footer={[
                        this.handleConfirmDelete(confirmDelete),
                        this.handleEditPressed(editPressed)
                    ]}
                >
                    <DetailsModalInfo
                        handleStateChange={this.handleStateChange}
                        rowData={rowData}
                        editOrNot={editPressed}
                        certificateTypes={certificateTypes.add}
                        contacts={contacts}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    }
}
