import * as React from 'react';
import { Layout } from 'antd';
import { Translate } from 'react-redux-i18n';

const { Content } = Layout;

export const FileNotFoundView: React.SFC = props => (
    <Layout>
        <Content>
            <h2
                style={{
                    textAlign: 'center'
                }}
            >
                <Translate value="FileNotFoundView.text" />
            </h2>
        </Content>
    </Layout>
);

export default FileNotFoundView;
