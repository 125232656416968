/* tslint:disable:no-any */
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Tooltip } from 'antd';

export class EditableTagGroup extends React.Component {
    input: any;

    constructor(props: any) {
        super(props);
        this.state = {
            tags: props.value,
            inputVisible: false,
            inputValue: ''
        };
        this.handleClose = this.handleClose.bind(this);
        this.showInput = this.showInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputConfirm = this.handleInputConfirm.bind(this);
        this.handleEnterForAnotherTag = this.handleEnterForAnotherTag.bind(
            this
        );
        this.saveInputRef = this.saveInputRef.bind(this);
    }

    handleClose(removedTag: any) {
        const tags = (this.state as any).tags.filter(
            (tag: any) => tag !== removedTag
        );
        this.setState({ tags });
        (this.props as any).onChange(tags);
    }

    showInput() {
        this.setState({ inputVisible: true }, () => this.input.focus());
    }

    handleInputChange(e: any) {
        this.setState({ inputValue: e.target.value });
    }

    handleInputConfirm() {
        const state = this.state as any;
        const inputValue = state.inputValue;
        let tags = state.tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        this.setState({
            tags,
            inputVisible: false,
            inputValue: ''
        });
        (this.props as any).onChange(tags);
    }

    handleEnterForAnotherTag() {
        this.handleInputConfirm();
        this.showInput();
    }

    saveInputRef = (input: any) => (this.input = input);

    render() {
        const { tags, inputVisible, inputValue } = this.state as any;
        const propsToSend = {
            onClick: this.showInput
        };
        return (
            <div style={{ marginLeft: 5 }}>
                {tags.map((tag: any, index: any) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag
                            key={tag}
                            closable={true}
                            onClose={() => this.handleClose(tag)}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleEnterForAnotherTag}
                    />
                )}
                {!inputVisible && (
                    <Tag
                        {...propsToSend}
                        style={{ background: '#FBFBFF', borderStyle: 'dashed' }}
                    >
                        <PlusOutlined /> New Tag
                    </Tag>
                )}
            </div>
        );
    }
}
