import { graphql, gql } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { QueryProps } from 'react-apollo/types';
import { DashboardView } from './DashboardView';

export const DashboardViewQuery = gql`
    query DashboardViewQuery {
        views {
            dashboard {
                lastRunStatsOverview {
                    lastRunStats {
                        newestRunDate
                        oldestRunDate
                    }
                }
                certificateStatsOverview {
                    certificateStatus {
                        type
                        count
                    }
                    updateAttemptStatus {
                        type
                        count
                    }
                }
            }
        }
    }
`;

export interface MapPropCompleteDefinition {
    data: QueryProps & MapPropDefinition;
    ownProps: RouteComponentProps<{}>;
}

export interface MapPropDefinition {
    views: {
        dashboard: {
            lastRunStatsOverview: {
                lastRunStats: {
                    newestRunDate: string;
                    oldestRunDate: string;
                };
            };
            certificateStatsOverview: {
                certificateStatus: {}[]
                updateAttemptStatus: {}[]
            }
        };
    };
}

export const mapProps = (props: MapPropCompleteDefinition) => {
    return {
        newestRunDate: props.data.loading
            ? 'loading...'
            : props.data.views.dashboard.lastRunStatsOverview.lastRunStats
                  .newestRunDate,
        oldestRunDate: props.data.loading
            ? 'loading...'
            : props.data.views.dashboard.lastRunStatsOverview.lastRunStats
                  .oldestRunDate,
        certificateStatus: props.data.loading
            ? []
            : props.data.views.dashboard.certificateStatsOverview.certificateStatus,
        updateStatus: props.data.loading
            ? []
            : props.data.views.dashboard.certificateStatsOverview.updateAttemptStatus
    };
};

export const DashboardViewContainer = graphql(DashboardViewQuery, {
    props: mapProps
})(DashboardView);
