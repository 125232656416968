/* tslint:disable:no-any */
import React from 'react';
import { Input, Checkbox, Tooltip } from 'antd';

export class InputAndCheck extends React.Component {
    input: any;

    constructor(props: any) {
        super(props);
        if (props.typeOf === 'text') {
            if (props.value != null) {
                this.state = {
                    inputValue: props.value,
                    typeOf: 'text',
                    hasToolTip: false
                };
            } else {
                this.state = {
                    inputValue: '',
                    typeOf: 'text',
                    hasToolTip: false
                };
            }
        } else {
            if (props.value != null) {
                this.state = {
                    inputValue: props.value,
                    typeOf: 'bool',
                    hasToolTip: false
                };
            } else {
                this.state = {
                    inputValue: false,
                    typeOf: 'bool',
                    hasToolTip: false
                };
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleInputConfirm = this.handleInputConfirm.bind(this);
        this.saveInputRef = this.saveInputRef.bind(this);
    }

    componentDidMount() {
        const isLonger =
            (this.state as any).inputValue.length > 20 ? true : false;
        this.setState({ hasToolTip: isLonger });
    }

    handleInputChange(e: any) {
        this.setState({ inputValue: e.target.value });
    }

    handleInputConfirm() {
        const state = this.state as any;
        const inputValue = state.inputValue;
        (this.props as any).onChange(inputValue);
        const isLonger = inputValue.length > 20 ? true : false;
        this.setState({ hasToolTip: isLonger });
    }

    handleCheckChange(e: any) {
        this.setState({ inputValue: e.target.checked });
        (this.props as any).onChange(e.target.checked);
    }

    saveInputRef = (input: any) => (this.input = input);

    render() {
        const { inputValue, typeOf, hasToolTip } = this.state as any;
        if (typeOf === 'text') {
            return (
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                    {!hasToolTip ? (
                        <Input
                            style={{ minWidth: '200px' }}
                            ref={this.saveInputRef}
                            type="text"
                            size="small"
                            value={inputValue}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm}
                            onPressEnter={this.handleInputConfirm}
                        />
                    ) : (
                        <Tooltip title={inputValue}>
                            <Input
                                style={{ minWidth: '200px' }}
                                ref={this.saveInputRef}
                                type="text"
                                size="small"
                                value={inputValue}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputConfirm}
                                onPressEnter={this.handleInputConfirm}
                            />
                        </Tooltip>
                    )}
                </div>
            );
        } else {
            return (
                <div style={{ marginLeft: 15, marginRight: 15 }}>
                    <Checkbox
                        value={inputValue}
                        onChange={this.handleCheckChange}
                    />
                </div>
            );
        }
    }
}
