/**
 * Created by geroe on 18.03.2016.
 */

/**
 * StoreConnector can be passed to a component as a prop and will then bind
 * the given handler of the given store to an event of the component it
 * is passed to.
 */
/* tslint:disable:no-any */
export class StoreConnector {
    _store: any;
    _handler: any;
    _args: any;

    constructor(store: any, handler: any, args: any) {
        this._store = store;
        this._handler = handler;
        this._args = args || [];
    }

    listenTo(listenable: any) {
        const args = [this._store].concat(this._args);

        this._store.listenTo(listenable, this._handler.bind(...args));
    }
}
