/**
 * Created by geroe on 19.04.2016.
 */
// import { mixin, TLoggable } from 'fifa-javascript-utilities/GeneralUtils';
/* tslint:disable:no-any */
import { assert } from './utils';
import { isString, pick } from 'lodash';

export class SimpleFilter {
    /**
     *
     * @param {string} field name of the field
     * @param {string} operator
     * @param value
     */

    _field: any;
    _operator: any;
    _value: any;

    constructor({ field, operator, value }: any) {
        this.field = field;
        this.operator = operator;
        this.value = value;
    }

    getPart(num: any) {
        const parts = this._field.split('.');

        return (parts.length > num && parts[num]) || null;
    }

    getPartsCount() {
        return this._field.split('.').length;
    }

    isLastPart(num: any) {
        return this._field.split('.').length - 1 === num;
    }

    get field() {
        return this._field;
    }

    set field(field: any) {
        // assert(field && isString(field), 'field must be set');
        this._field = field;
    }

    get operator() {
        return this._operator;
    }

    set operator(operator: any) {
        assert(operator && isString(operator), 'operator must be set');
        this._operator = operator;
    }

    get value() {
        return this._value;
    }

    set value(value: any) {
        assert(value !== undefined, 'value must be defined');
        this._value = value;
    }

    serialize() {
        return pick(this, 'field', 'operator', 'value');
    }
}
