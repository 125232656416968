import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import {
    PresentationalPagination,
    PaginationDataProps,
    PaginationFunctionProps
} from './Pagination';
import { createPushAction } from '../../util/UrlUtil';
import { State, FilterURLType } from './../../store';
import { FilterRowRender } from '../filter/Filter';

export interface FilterProps {
    filters: Array<FilterRowRender>;
    paginationOptions: {
        activePage: number;
        rowsPerPage: number;
    };
}

export const mapStateToProps = (
    state: State,
    ownProps: PaginationDataProps & RouteComponentProps<FilterURLType>
) => ({
    filter: ownProps.match.params.filter,
    numberOfItems: ownProps.numberOfItems,
    rowsPerPageOptions: ownProps.rowsPerPageOptions
});

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    ownProps: RouteComponentProps<FilterURLType>
): PaginationFunctionProps => ({
    onSelectPage: (activePage: number, filter: FilterProps) => {
        const paginationOptions = Object.assign({}, filter.paginationOptions, {
            activePage: activePage
        });
        const filters = Object.assign({}, filter, { paginationOptions });
        dispatch(
            createPushAction({
                path: ownProps.match.path,
                pathType: 'absolute',
                filters: filters
            })
        );
    },
    onChangeRowsPerPage: (rowsPerPage: number, filter: FilterProps) => {
        const filters = Object.assign({}, filter, {
            paginationOptions: {
                activePage: 1,
                rowsPerPage
            }
        });
        dispatch(
            createPushAction({
                path: ownProps.match.path,
                pathType: 'absolute',
                filters: filters
            })
        );
    }
});

export const URLPagination = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PresentationalPagination);
