import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
    MutationFunc,
    compose,
    graphql,
    gql,
    ApolloError,
    withApollo,
    ApolloClient,
    OptionProps
} from 'react-apollo';
import {message} from 'antd';
import {State} from '../../store';
import {setAddCertificateModalVisibility} from './AddCertificateReducer';
import {
    AddCertificateModal,
    AddCertificateModalActionProps
} from './AddCertificateModal';
import {ContactTableViewQuery} from '../../view/contactTable/details/ContactTableViewContainer';
import {AddContactResult} from '../addContactInteraction/AddContactModalContainer';

export interface AddCertificateResult {
    contacts: Array<string>;
    domainName: string;
    salesLines: Array<string>;
    type: string;
    port: number;
}

export interface AddCertificateVariables {
    contacts: Array<string>;
    domainName: string;
    salesLines: Array<string>;
    type: string;
    port: number;
}

interface QueryDataProps {
    views: {
        contacts: {
            contactOverview: {
                elements: [AddContactResult];
            };
        };
    };
}

export interface AddCertificateModalContainerProps {
    reportName: string;
}

export interface AddCertificateModalContainerDataProps {
    visible: boolean;
    certificateTypes: string[];
}

export const mapStateToProps = (
    state: State,
    {reportName}: AddCertificateModalContainerProps
): AddCertificateModalContainerDataProps => {
    return {
        visible:
            state.cia.addCertificateInteraction.visibleOnReport.get(
                reportName
            ) || false,
        certificateTypes: state.cia.claimsReducer.claims.certificate.add
    };
};

export const mapDispatchToProps = (
    dispatch: Dispatch<{}>,
    {
        reportName,
        mutate,
        client
    }: AddCertificateModalContainerProps & {
        mutate: MutationFunc<AddCertificateResult, AddCertificateVariables>;
        client: ApolloClient;
    }
): AddCertificateModalActionProps => ({
    onCancel: () =>
        dispatch(setAddCertificateModalVisibility(reportName, false)),
    onSubmit: async (values, form) => {
        try {
            await mutate({
                variables: {
                    ...values
                }
            });
            if (form !== null) {
                form.resetFields();
            }
            dispatch(setAddCertificateModalVisibility(reportName, false));
            client.resetStore();
            message.success('Successfully added your certificate!');
            return true;
        } catch (error) {
            if (error.graphQLErrors) {
                const apolloError = error as ApolloError;
                for (const err of apolloError.graphQLErrors) {
                    message.error(err.message);
                }
            }
            return false;
        }
    }
});

const certificateMutation = gql`
    mutation(
        $domainName: SaveStringType!
        $contacts: [SaveStringType!]!
        $type: CertificateTypeEnum!
        $salesLines: [SalesLineEnum!]!
        $port: Int
        $selfSigned: Boolean
    ) {
        certificate {
            add(
                domainName: $domainName
                contacts: $contacts
                type: $type
                salesLines: $salesLines
                certPort: $port
                selfSigned: $selfSigned
            ) {
                domainName
            }
        }
    }
`;

export const mapProps = (props: OptionProps<{}, QueryDataProps>) => {
    if (props.data?.error) {
        return {
            contacts: [{name: ''}]
        };
    }

    if (props.data !== undefined) {
        return {
            contacts: props.data.loading
                ? [{name: ''}]
                : props.data.views.contacts.contactOverview.elements
        };
    } else {
        return {
            contacts: [{name: ''}]
        };
    }
};

export const AddCertificateModalContainer: React.ComponentClass<
    AddCertificateModalContainerProps
> = compose(
    withApollo,
    graphql(ContactTableViewQuery, {
        props: mapProps,
        options: {
            variables: {
                first: 10000
            }
        }
    }),
    graphql(certificateMutation),
    connect(mapStateToProps, mapDispatchToProps)
)(AddCertificateModal);
