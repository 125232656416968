import * as React from 'react';
import { AddContactDisplayContainer } from '../../../component/addContactInteraction/AddContactDisplayContainer';

export const Columns = (reportName: string, visibleColumns: string[]) => {
    const completeColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: '',
            key: 'edit',
            render: (text: string, record: {}, index: number) => (
                <div>
                    <AddContactDisplayContainer
                        reportName={reportName}
                        data={record}
                        functionality={'EDIT'}
                    />
                    <span style={{ marginLeft: '5px' }}>
                        <AddContactDisplayContainer
                            reportName={reportName}
                            data={record}
                            functionality={'REMOVE'}
                        />
                    </span>
                </div>
            )
        }
    ];

    var selectedColumns = completeColumns;

    if (visibleColumns !== undefined) {
        selectedColumns = completeColumns.filter(column => {
            return visibleColumns.indexOf(column.key) === -1;
        });
    }

    return selectedColumns;
};
