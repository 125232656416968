import { TargetType, EnumTargetType } from './Filter';

export interface FilterMapper {
    targetTypeComponentMappings: Array<ComponentMapping>;
}

export interface FilterInputComponentProps {
    onValueChange: (newValue: string | boolean | {}) => void;
    onDeselectChange: (removeValue: string) => void;
    targetType: TargetType;
    defaultTargetTypeValue: Array<string> | string;
}

export type RComponent = React.SFC;

export interface FilterInputComponent {
    singleSelectComponent?: RComponent;
    multiSelectComponent?: RComponent;
}

export type ComponentMapping = Map<string, FilterInputComponent>;

export enum ComponentType {
    single = 'singleSelectComponent',
    multi = 'multiSelectComponent'
}

export class FilterMapper {
    constructor(...mappings: Array<ComponentMapping>) {
        this.targetTypeComponentMappings = mappings;
    }

    getSimpleComponentByTargetType(targetType: TargetType): RComponent {
        return this.getComponentByTargetType(targetType, ComponentType.single);
    }

    getMultiComponentByTargetType(targetType: TargetType): RComponent {
        return this.getComponentByTargetType(targetType, ComponentType.multi);
    }

    private getComponentByTargetType(
        targetType: TargetType,
        type: ComponentType
    ): RComponent {
        const selector = (targetType as EnumTargetType).isEnum
            ? 'Enum'
            : targetType.id;
        for (const map of this.targetTypeComponentMappings) {
            const component: any = map.get(selector);

            if (component) {
                return component[type];
            }
        }
        throw new Error(
            `Could not find matching Component for TargetType[${JSON.stringify(
                targetType
            )}]`
        );
    }
}
