/* tslint:disable:no-any */
import { push } from 'react-router-redux';
import { toLower } from 'lodash';
import { UrlPathTypes } from './UrlPathTypes';
import { FilterAggregator } from '../component/ciaFilter/filterService/FilterAggregator';
import { mapFilterArrayToSimpleFilters } from '../component/ciaFilter/FilterPresentational';

export const defaultPaginatonOptions = {
    paginationOptions: {
        rowsPerPage: 10,
        activePage: 1
    },
    filters: []
};

export const convertBase64ToObjectWithFallback = (
    someBase64: any,
    fallback: any
) => {
    let someObject;

    try {
        someObject = base64ToObject(someBase64);
    } catch (ex) {
        someObject = fallback;
    }
    return someObject;
};

export const objectToBase64 = (someObject: any) =>
    btoa(JSON.stringify(someObject));

export const base64ToObject = (someBase64: any) => JSON.parse(atob(someBase64));

export const createPushAction = ({ path, pathType, filters }: any) =>
    push(
        formatPath({
            path,
            pathType
        }).replace(':filter?', objectToBase64(filters))
    );

export const formatPath = ({
    path = '/',
    pathType = UrlPathTypes.absolute
} = {}) => {
    const pathWithSlashEnding = path.endsWith('/') ? path : `${path}/`;

    switch (toLower(pathType)) {
        case UrlPathTypes.absolute:
            return pathWithSlashEnding.startsWith('/')
                ? pathWithSlashEnding
                : `/${pathWithSlashEnding}`;
        case UrlPathTypes.relative:
            return pathWithSlashEnding.startsWith('/')
                ? `${pathWithSlashEnding.replace('/', '')}`
                : pathWithSlashEnding;
        default:
            return pathWithSlashEnding;
    }
};

export const mapUrlToOptions = (filter: any) => {
    const propsFromUrl = convertBase64ToObjectWithFallback(
        filter,
        defaultPaginatonOptions
    );
    const queryFilter = propsFromUrl.filters
        ? new FilterAggregator({
              filters: mapFilterArrayToSimpleFilters(propsFromUrl.filters)
          }).graphQLExpression()
        : undefined;

    return {
        variables: {
            skip:
                propsFromUrl.paginationOptions.rowsPerPage *
                (propsFromUrl.paginationOptions.activePage - 1),
            first: propsFromUrl.paginationOptions.rowsPerPage,
            filters: queryFilter
        }
    };
};
