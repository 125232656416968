import * as React from 'react';
import { Table, Layout } from 'antd';
import { URLPagination } from '../../../component/pagination';
import { Columns } from './ContactTableViewColumns';
import { AddContactDisplayContainer } from '../../../component/addContactInteraction/AddContactDisplayContainer';
import { AddContactModalContainer } from '../../../component/addContactInteraction/AddContactModalContainer';
import { FilterBox } from '../../../component/filterBox/FilterBox';

const { Content } = Layout;

export interface ContactTableDetails {
    id: string;
    name: string;
    username: string;
    email: string;
    phonenumber: string;
}

export interface ContactTableProps {
    rowData: Array<ContactTableDetails>;
    numberOfRows: number;
    loading: boolean;
    visibleColumns: string[];
    error?: {
        message?: string;
    };
}

export const ContactTableView: React.SFC<ContactTableProps> = ({
    loading,
    rowData,
    numberOfRows,
    visibleColumns,
    error
}) => {
    const propsToSend = {
        numberOfItems: numberOfRows,
        rowsPerPageOptions: ['10', '25', '50', '100']
    };
    const propsForFilter = {
        type: 'ContactViewModelType',
        reportName: 'CONTACT',
        columnsAvailable: columnsAvailable
    };
    if (error) {
        return (
            <Content>
                <div>
                    <h3>An Error occured during your Request</h3>
                    <p>
                        {error.message &&
                            error.message.indexOf(
                                'GraphQL error: Cannot view contacts'
                            ) >= 0
                            ? 'It seems like you do not have the rights to access this view'
                            : ''}
                    </p>
                </div>
            </Content>
        );
    }
    return (
        <Content>
            <FilterBox {...propsForFilter} />
            <h1 style={{ textAlign: 'center' }}>Contacts</h1>
            <div style={{ marginBottom: '5px' }}>
                <URLPagination {...propsToSend} />
            </div>
            <Table
                loading={loading}
                rowKey={(record: ContactTableDetails) => record.id}
                pagination={false}
                columns={Columns('ADDCONTACT', visibleColumns)}
                dataSource={rowData}
                bordered={false}
                size="small"
            />
            <URLPagination {...propsToSend} />
            <AddContactDisplayContainer
                reportName={'ADDCONTACT'}
                functionality={'ADD'}
                data={''}
            />
            <AddContactModalContainer reportName={'ADDCONTACT'} />
        </Content>
    );
};

const columnsAvailable = [
    // {key: 'name', name:'Name'},
    { key: 'username', name: 'Username' },
    { key: 'email', name: 'Email' },
    { key: 'phoneNumber', name: 'Phone Number' }
];
