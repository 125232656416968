/**
 * Created by geroe on 22.04.2016.
 */
// import { mixin, TLoggable } from 'fifa-javascript-utilities/GeneralUtils';
/* tslint:disable:no-any */
import { SimpleFilter } from './FilterSchema';
import { assert, isArrayOf } from './utils';
import { chain, every, some } from 'lodash';

export class FilterAggregator {
    _filters: any;

    static _reduceFilter(filter: any) {
        assert(filter instanceof SimpleFilter, 'need a SimpleFilter');
        const field = chain(filter.field)
            .split('.')
            .tail()
            .join('.')
            .value();

        return new SimpleFilter({
            field,
            operator: filter.operator,
            value: filter.value
        });
    }

    static _getFirstPart(filter: any) {
        assert(filter instanceof SimpleFilter, 'need a SimpleFilter');

        return filter.field.split('.').concat(filter.operator)[0];
    }

    static _isLastPart(filter: any) {
        assert(filter instanceof SimpleFilter, 'need a SimpleFilter');

        return filter.field.split('.').length === 1;
    }

    static _graphQLLeaf(filters: any) {
        return chain(filters)
            .groupBy(filter => filter.operator)
            .mapValues((afilters: any) => {
                assert(afilters.length === 1, 'invariant violation');

                return filters[0].value;
            })
            .value();
    }

    static _graphQLNode(filters: any): any {
        return chain(filters)
            .groupBy(FilterAggregator._getFirstPart) // group by filter name
            .mapValues((afilters: any) => {
                if (some(afilters, FilterAggregator._isLastPart)) {
                    assert(
                        every(afilters, FilterAggregator._isLastPart),
                        'invariant violation'
                    );
                    return FilterAggregator._graphQLLeaf(afilters);
                }

                const result = filters.map(FilterAggregator._reduceFilter);
                return FilterAggregator._graphQLNode(result);
            })
            .value();
    }

    constructor({ filters }: any) {
        assert(
            isArrayOf(filters, SimpleFilter),
            'Filters must be array of SimpleFilters'
        );
        this._filters = filters;
    }

    get filters() {
        return this._filters;
    }

    serialize() {
        return {
            filters: this._filters.map((filter: any) => filter.serialize())
        };
    }

    graphQLExpression() {
        return FilterAggregator._graphQLNode(this._filters);
    }
}
