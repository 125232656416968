import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Dispatch } from 'redux';

interface LocationRedirectProps {
    redirect: (formerPath: string) => void;
}

export const LocationRedirect = ({ redirect }: LocationRedirectProps) => {
    if (localStorage) {
        const formerPath = localStorage.getItem('loginRedirect');
        if (formerPath) {
            localStorage.removeItem('loginRedirect');
            redirect(formerPath);
        }
    }
    return null;
};

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
    redirect: (url: string) => dispatch(push(url))
});

export const LocationRedirectContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationRedirect);
