import { Action } from 'redux';
import { Map } from 'immutable';

export interface ColumnToggleInteractionAction<T> extends Action {
    payload: T;
}

export interface ColumnToggleModalPayload {
    reportName: string;
    visibleColumns: string[];
}

export const setColumnsVisibility = (
    reportName: string,
    visibleColumns: string[]
): ColumnToggleInteractionAction<ColumnToggleModalPayload> => ({
    type: ColumnToggleInteractionActions.SetColumnVisibilityOnPage,
    payload: {
        visibleColumns,
        reportName
    }
});

export const defaultState: ColumnToggleActionState = {
    columnsVisible: Map()
};

export interface ColumnToggleActionState {
    columnsVisible: Map<string, string[]>;
}

export enum ColumnToggleInteractionActions {
    SetColumnVisibilityOnPage = 'ColumnToggleInteraction: setColumnVisibilityOnPage'
}

export const SetColumnToggleReducer = (
    state: ColumnToggleActionState = defaultState,
    action: ColumnToggleInteractionAction<ColumnToggleModalPayload>
) => {
    switch (action.type) {
        case ColumnToggleInteractionActions.SetColumnVisibilityOnPage:
            const payload = action.payload as ColumnToggleModalPayload;
            return {
                ...state,
                columnsVisible: state.columnsVisible.set(
                    payload.reportName,
                    payload.visibleColumns
                )
            };
        default:
            return state;
    }
};
