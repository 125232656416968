import * as React from 'react';
import { Input, Select, DatePicker, Checkbox } from 'antd';
import { EnumTargetType } from './Filter';
import { FilterInputComponentProps } from './FilterMapper';
import './Filter.css';
var moment = require('moment');

export const SingleStringInput: React.SFC<FilterInputComponentProps> = ({
    targetType,
    onValueChange,
    onDeselectChange,
    defaultTargetTypeValue
}) => (
    <Input
        className="filter_control-default"
        type="text"
        size="small"
        onChange={event => onValueChange(event.target.value)}
        defaultValue={defaultTargetTypeValue}
    />
);

export const MultiStringInput: React.SFC<FilterInputComponentProps> = ({
    targetType,
    onValueChange,
    onDeselectChange,
    defaultTargetTypeValue
}) => (
    <Select
        className="filter_control-default"
        mode="tags"
        size="small"
        onSelect={onValueChange}
        defaultValue={defaultTargetTypeValue}
    />
);

export const SingleNumberInput: React.SFC<FilterInputComponentProps> = ({
    onValueChange,
    targetType,
    onDeselectChange,
    defaultTargetTypeValue
}) => {
    return (
        <Input
            className="filter_control-default"
            type="number"
            size="small"
            onChange={event => onValueChange(event.target.value)}
            defaultValue={defaultTargetTypeValue}
        />
    );
};

export const DistinctNumberInput: React.SFC<
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >
> = props => {
    return (
        <input
            type="number"
            {...{
                ...props,
                ...{
                    onKeyPress: event => {
                        if (event.which < 47 || event.which > 57) {
                            event.preventDefault();
                        }
                    }
                }
            }}
            value="123"
        />
    );
};

export const MultiNumberInput: React.SFC<FilterInputComponentProps> = ({
    onValueChange,
    targetType,
    onDeselectChange,
    defaultTargetTypeValue
}) => (
    <Select
        className="filter_control-default"
        mode="tags"
        size="small"
        defaultValue="123123123"
    />
);

export const SingleDateInput: React.SFC<FilterInputComponentProps> = ({
    onValueChange,
    targetType,
    onDeselectChange,
    defaultTargetTypeValue
}) => (
    <DatePicker
        className="filter_control-default"
        size="small"
        showTime={true}
        format="DD-MM-YYYY HH:mm:ss"
        showToday={true}
        onChange={date => onValueChange(date != null ? date.toISOString() : "")}
        defaultValue={moment(defaultTargetTypeValue)}
    />
);

export const SingleBoolInput: React.SFC<FilterInputComponentProps> = ({
    onValueChange,
    targetType,
    onDeselectChange,
    defaultTargetTypeValue
}) => (
    <Checkbox
        className="filter_control-default"
        style={{ textAlign: 'center' }}
        onChange={event => onValueChange(event.target.checked)}
        defaultChecked={!!defaultTargetTypeValue}
    />
);

export const EnumInput = (
    multiple: boolean
): React.SFC<FilterInputComponentProps> => ({
    onValueChange,
    targetType,
    onDeselectChange,
    defaultTargetTypeValue
}) => (
        <Select
            className="filter_control-default"
            mode={multiple ? 'multiple' : 'tags'}
            size="small"
            onSelect={onValueChange}
            onDeselect={onDeselectChange}
            defaultValue={defaultTargetTypeValue}
        >
            {(targetType as EnumTargetType).values.map(val => (
                <Select.Option key={val} value={val}>
                    {val}
                </Select.Option>
            ))}
        </Select>
    );
