/**
 * Created by geroe on 08.04.2016.
 */
/* tslint:disable:no-any */
import { FilterDefinition, FilterSchema } from '../filterService/FilterSchema';
import { FilterOperator, FilterType } from '../filterService/FilterOperator';
import { FilterDataType } from '../filterService/FilterType';
import { identity } from 'lodash';
import { gql } from 'react-apollo';

const inputFieldQuery = gql`
    query($typeName: String!) {
        __type(name: $typeName) {
            name
            inputFields {
                name
                type {
                    name
                    kind
                    ofType {
                        name
                        kind
                    }
                }
            }
        }
    }
`;

const TypeMapping = {
    String: FilterDataType.STRING,
    Int: FilterDataType.INT,
    Float: FilterDataType.FLOAT,
    Boolean: FilterDataType.BOOL,
    DateTime: FilterDataType.DATETIME
};

export class GraphQLHelpers {
    _client: any;
    _endpoint: any;

    static _operatorFromArgs(props: any) {
        const buildType = (type: any) => {
            switch (type.kind) {
                case 'SCALAR':
                    return new FilterType({
                        dataType: TypeMapping[type.name]
                    });
                case 'LIST':
                    return new FilterType({
                        dataType: TypeMapping[type.ofType.name],
                        isList: true
                    });
                default:
                    throw new Error(`unknown kind ${type.kind}`);
            }
        };

        return new FilterOperator({
            name: props.name,
            type: buildType(props.type)
        });
    }

    constructor(endpoint: any, client: any) {
        this._client = client;
        this._endpoint = endpoint;
        this._filterDefinitionFromArgs = this._filterDefinitionFromArgs.bind(
            this
        );
    }

    async requestTypeInputFields(typeName: any) {
        return this._client
            .query({
                query: inputFieldQuery,
                variables: { typeName }
            })
            .then((r: any) => r.data.__type);
    }

    async _filterDefinitionFromArgs({ name, type }: any) {
        const isFilter = type.name.endsWith('Filter');
        const isNested = type.name.endsWith('InputType');
        const filterType = await this.requestTypeInputFields(type.name);

        if (isFilter) {
            return new FilterDefinition({
                name,
                operators: filterType.inputFields.map((field: any) =>
                    GraphQLHelpers._operatorFromArgs(field)
                )
            });
        } else if (isNested) {
            return new FilterDefinition({
                name,
                subFields: await Promise.all(
                    filterType.inputFields.map(this._filterDefinitionFromArgs)
                )
            });
        } else {
            return {};
        }
    }

    async filterSchemaFromType(type: any) {
        const schema = await this.requestTypeInputFields(type);
        const rawFields = schema.inputFields.filter(
            (field: any) => field.type.kind === 'INPUT_OBJECT'
        );
        const definitions = await Promise.all(
            rawFields.map((f: any) => this._filterDefinitionFromArgs(f))
        );

        return new FilterSchema({ definitions: definitions.filter(identity) });
    }
}
